import { types } from '/@vuex/types'
import {
  defaultUserName,
  defaultUserPassword,
  defaultCommRequestPortSecure,
  defaultCommReceivePortSecure,
} from '/@shared/config'
import { vueDeepSet } from '/@/shared/utils'
import _get from 'lodash.get'

const initialState = () => {
  return {
    connection: {
      open: false,
      login: true,
      secure: true,
      hosts: {
        general: '',
        deployment: '',
      },
      ports: {
        request: defaultCommRequestPortSecure,
        receive: defaultCommReceivePortSecure,
      },
      user: {
        name: defaultUserName,
        password: defaultUserPassword,
      },
    },
    parameterList: [],
    parameterIndex: [],
  }
}

const moduleMotorCortex = {
  state: {
    ...initialState(),
  },
  mutations: {
    [types.MOTORCORTEX_ATTRIBUTE_UPDATE](state, payload) {
      vueDeepSet(state, payload.key, payload.value)
    },
    [types.MOTORCORTEX_ATTRIBUTE_REALTIME_UPDATE](state, payload) {
      state[payload.key] = payload.value
    },
    [types.MOTORCORTEX_LOAD](state, value) {
      state.connection.hosts.general = value.connection.hosts.general
      state.connection.hosts.deployment = value.connection.hosts.deployment
      state.connection.login = value.connection.login
      state.connection.secure = value.connection.secure
      state.connection.ports.request = value.connection.ports.request
      state.connection.ports.receive = value.connection.ports.receive
      state.connection.user.name = value.connection.user.name
      state.connection.user.password = value.connection.user.password
    },
  },
  getters: {
    [types.MOTORCORTEX_STATE]: (state) => {
      return state
    },
    [types.MOTORCORTEX_ATTRIBUTE]: (state) => (key) => {
      return _get(state, key)
    },
  },
}

export default moduleMotorCortex
