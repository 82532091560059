import { types } from '/@vuex/types'
import _get from 'lodash.get'
import { vueDeepSet } from '/@/shared/utils'
import { gridSaveStatus } from '/@shared/constants'

const initialState = () => {
  return {
    title: 'untitled',
    version: '',
    saveStatus: gridSaveStatus.DEFAULT,
  }
}

const moduleGrid = {
  state: {
    ...initialState(),
  },
  mutations: {
    [types.GRID_LOAD](state, value) {
      state.title = value.title
      state.version = value.version
    },
    [types.GRID_ATTRIBUTE_UPDATE](state, payload) {
      vueDeepSet(state, payload.key, payload.value)
    },
    [types.GRID_MANUAL_SAVE]() {
      // used for communication between storage plugin and save component in topbar.
    },
  },
  getters: {
    [types.GRID_STATE]: (state) => {
      return state
    },
    [types.GRID_ATTRIBUTE]: (state) => (key) => {
      return _get(state, key)
    },
  },
}

export default moduleGrid
