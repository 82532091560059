import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelFilelist extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vFileList
    this._title = 'filelist'

    this._folderurl = {
      sortorder: 2,
      title: 'folder url',
      value: '/log',
      group: 'value',
      inputComponentType: inputComponentTypes.input,
    }

    this._showDate = {
      sortorder: 2,
      title: 'show date',
      group: 'style',
      value: true,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._showSize = {
      sortorder: 2,
      title: 'show size',
      group: 'style',
      value: true,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._sortBy = {
      sortorder: 3,
      group: 'style',
      title: 'sort by',
      options: [
        {
          title: 'name',
          value: 'name',
        },
        {
          title: 'size',
          value: 'size',
        },
        {
          title: 'date',
          value: 'date',
        },
      ],
      value: 'date',
      inputComponentType: inputComponentTypes.radio,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 200,
      inputComponentType: inputComponentTypes.number,
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 400,
      inputComponentType: inputComponentTypes.number,
    }
  }
}
