import mixinArtboards from '/@mixins/artboards'
import { storeTypes, mapStoreGetters } from '/@/vuex/helpers'
import { colorScheme } from '/@/shared/constants'
import { eventsGlobal } from '/@constants/events'

export default {
  mixins: [mixinArtboards],
  data() {
    return {
      isExpanded: false,
      colorScheme,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.ARTBOARDS, [
      'startID',
      'dimensions.width',
      'dimensions.height',
      'colors.foreground',
      'colors.background',
    ]),
    ...mapStoreGetters(storeTypes.SETTINGS, ['timePath', 'application.fitToScreen']),
    title: {
      get() {
        return this.artboardActive.title
      },
      set(value) {
        this.artboardsUpdateArtboardAttribute({
          artboard: this.artboardActive,
          key: 'title',
          value: value,
        })
      },
    },
    groupName: {
      get() {
        return this.artboardActive.groupName
      },
      set(value) {
        this.artboardsUpdateArtboardAttribute({
          artboard: this.artboardActive,
          key: 'groupName',
          value: value,
        })
      },
    },
    freqDivider: {
      get() {
        return this.artboardActive.frequencyDivider
      },
      set(value) {
        this.artboardsUpdateArtboardAttribute({
          artboard: this.artboardActive,
          key: 'frequencyDivider',
          value: value,
        })
      },
    },
    visiblePath: {
      get() {
        return this.artboardActive.visibility.path
      },
      set(value) {
        this.artboardsUpdateArtboardAttribute({
          artboard: this.artboardActive,
          key: 'visibility.path',
          value: value,
        })
      },
    },
    startArtboardOptions() {
      return Object.values(this.artboards.dictionary).map(({ id, title }) => {
        return {
          key: id,
          label: title,
        }
      })
    },
    isDeleteScreenButtonVisible() {
      return Object.keys(this.artboards.dictionary).length > 1
    },
  },
  methods: {
    init() {
      this.$bus.$on(eventsGlobal.NAV_CLICKED, () => {
        this.openPanel()
      })
    },
    openPanel() {
      this.isExpanded = false
      this.$nextTick(() => {
        this.isExpanded = true
      })
    },
    deleteArtboard(id) {
      if (confirm('Are you sure you want to delete this artboard?')) {
        this.artboardsDelete(id)
        this.redirectToFirstArtboard()
      }
    },
    onVisibilitySettingChanged(event) {
      this.artboardsUpdateArtboardAttribute({
        artboard: this.artboardActive,
        key: `visibility.${event.type}`,
        value: event.value,
      })
    },
  },
  mounted() {
    this.init()
  },
}
