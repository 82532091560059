import { Ref, ref } from 'vue'
import { empty } from '/@shared/utils'
import { ModelAttribute } from '/@shared/interfaces'
import expressionEval from 'expression-eval'
import { TypeInfo, useCommunication } from '../communication'

type Registration = {
  modelAttribute: ModelAttribute
  ref: Ref
  dataType: Ref<TypeInfo & { type?: string } | null>
}

type State = {
  subscription: any
  registrations: Registration[]
  updateIntervalReference: number
}

const state: State = {
  subscription: null,
  registrations: [],
  updateIntervalReference: 0,
}

export const useComponentData = () => {
  const communication = useCommunication()

  const init = (subscription: any) => {
    state.subscription = subscription
    state.registrations = []
  }

  const startUpdate = (frequencyDivider: number) => {
    stopUpdate()
    state.updateIntervalReference = window.setInterval(updateRegistrations, frequencyDivider)
  }

  const stopUpdate = () => {
    flushRegistrations()
    window.clearInterval(state.updateIntervalReference)
  }

  const registerSubscriptionType = (registration: Registration) => {
    if (registration.dataType.value) {
      return
    }

    const path = registration.modelAttribute.value
    const typeInfo = communication.getTypeInfoForPath(path)

    if (typeInfo) {
      registration.dataType.value = {
        ...typeInfo,
        type: communication.getDataType(typeInfo.dataType)
      }
    }
  }

  const updateRegistrations = () => {
    state.registrations.forEach((registration) => {
      const prefix = registration.modelAttribute?.settings?.prefix || ''
      const path = prefix + registration.modelAttribute.value

      if (!communication.isPathValid(path)) {
        registration.ref.value = null
        return
      }

      registerSubscriptionType(registration)

      let value = state.subscription?.getPathValue(path)

      if (value !== null && value !== undefined) {
        value = incorporateArraySlotFromAttribute(registration.modelAttribute, value)
        value = incorporateExpressionFromAttribute(registration.modelAttribute, value)

        registration.ref.value = value
      }
    })
  }

  const incorporateArraySlotFromAttribute = (modelAttribute: ModelAttribute, value: any) => {
    return incorporateArraySlot(modelAttribute?.settings?.slot, value)
  }

  const incorporateExpressionFromAttribute = (modelAttribute: ModelAttribute, value: any) => {
    return incorporateExpression(modelAttribute?.settings?.expression, value)
  }

  const incorporateArraySlot = (slot: number | null, value: any) => {
    const isArray = Array.isArray(value)

    if (!isArray || empty(slot)) {
      return value
    }

    if ((slot as number) > value.length - 1) {
      return null
    }

    return value[slot as number]
  }

  const incorporateExpression = (expression: string | null, value: any) => {
    if (empty(value)) {
      return null
    }

    if (empty(expression) || !validateExpression(expression as string)) {
      return value
    }

    try {
      // @ts-ignore
      const abstractSyntaxTree = expressionEval.parse(expression)
      const result = expressionEval.eval(abstractSyntaxTree, {
        x: value,
      })

      return result
    } catch (error) {
      return value
    }
  }

  const validateExpression = (expression: string) => {
    if (expression.includes('constructor')) {
      return false
    }

    if (expression.length > 30) {
      return false
    }

    try {
      // @ts-ignore
      const abstractSyntaxNode = expressionEval.parse(expression)

      if (abstractSyntaxNode.type !== 'BinaryExpression') {
        return false
      }

      return validateAbstractSyntaxNode(abstractSyntaxNode)
    } catch (e) {
      return false
    }
  }

  const validateAbstractSyntaxNode = (abstractSyntaxNode: any): any => {
    let result = true

    const { left, right } = abstractSyntaxNode

    if (left.type === 'Identifier' && left.name !== 'x') {
      result = false
    } else if (left.type === 'BinaryExpression') {
      result = validateAbstractSyntaxNode(left)
    }

    if (right.type === 'Identifier' && right.name !== 'x') {
      result = false
    } else if (right.type === 'BinaryExpression') {
      result = validateAbstractSyntaxNode(right)
    }

    return result
  }

  const register = (modelAttribute: ModelAttribute, defaultValue: any = false) => {
    const registration: Registration = {
      modelAttribute,
      ref: ref(defaultValue),
      dataType: ref(null),
    }

    state.registrations.push(registration)

    return registration.ref
  }

  const flushRegistrations = () => {
    state.registrations = []
  }

  const getDataTypeForPath = (path: string) => {
    return state.registrations.find((registration) => {
      return registration.modelAttribute.value === path
    })?.dataType
  }

  return {
    init,
    stopUpdate,
    startUpdate,
    register,
    flushRegistrations,
    validateExpression,
    incorporateExpression,
    incorporateArraySlot,
    getDataTypeForPath,
  }
}
