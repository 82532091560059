import { types } from '/@vuex/types'
import { uuid, vueDeepSet, empty, arrayWrap } from '/@shared/utils'
import Vue from 'vue'
import _get from 'lodash.get'
import _set from 'lodash.set'
import _cloneDeep from 'lodash.clonedeep'
import factory from '/@models/factory'
import router from '/@/shared/router'

export const artboardTemplate = {
  id: '',
  title: 'screen 1',
  visibility: {
    path: '',
    slot: 0,
    expression: '',
  },
  components: {},
  frequencyDivider: 100,
}

const initialState = () => {
  const artboardUUID = uuid()
  const dictionary = {
    [artboardUUID]: {
      ..._cloneDeep(artboardTemplate),
      ...{
        id: artboardUUID,
      },
    },
  }

  return {
    order: [artboardUUID],
    dictionary: Object.assign({}, dictionary),
    counter: 1,
    programUpdateCounter: 0,
    startID: artboardUUID,
    models: {
      active: [],
    },
    dimensions: {
      width: 800,
      height: 600,
    },
    colors: {
      foreground: '#313c4f',
      background: '#282f3a',
    },
  }
}

const moduleArtboards = {
  state: {
    ...initialState(),
  },

  mutations: {
    [types.ARTBOARDS_LOAD](state, value) {
      let startID = value.startID

      if (empty(startID)) {
        const availableIDs = Object.keys(value.dictionary)
        startID = empty(availableIDs) ? '' : availableIDs[0]
      }

      state.models.active = []
      state.counter = value.counter
      state.dictionary = value.dictionary
      state.order = value.order
      state.startID = startID
      state.colors.foreground = value.colors.foreground
      state.colors.background = value.colors.background
      state.dimensions.width = value.dimensions.width
      state.dimensions.height = value.dimensions.height
    },
    [types.ARTBOARDS_ATTRIBUTE_UPDATE](state, payload) {
      vueDeepSet(state, payload.key, payload.value)
    },
    [types.ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE](state, { artboard, key, value }) {
      vueDeepSet(state.dictionary[artboard.id], key, value)
    },
    [types.ARTBOARDS_ADD](state, { artboardID }) {
      state.counter++

      Vue.set(state.dictionary, artboardID, {
        ..._cloneDeep(artboardTemplate),
        ...{
          id: artboardID,
          title: `screen ${state.counter}`,
        },
      })

      if (empty(state.startID)) {
        state.startID = artboardID
      }

      state.order.push(artboardID)
    },
    [types.ARTBOARDS_DELETE](state, artboardUUID) {
      Vue.delete(state.dictionary, artboardUUID)
      state.order.splice(state.order.indexOf(artboardUUID), 1)

      if (artboardUUID === state.startID) {
        const artboardIDs = Object.keys(state.dictionary)
        state.startID = artboardIDs.length > 0 ? artboardIDs[0] : null
      }
    },
    [types.MODEL_ADD](state, { artboard, model }) {
      const models = arrayWrap(model)

      models.forEach((m) => {
        Vue.set(state.dictionary[artboard.id].components, m.id, m)
      })

      state.models.active = models
    },
    [types.MODEL_DELETE](state, { artboard, model }) {
      arrayWrap(model).forEach((model) => {
        Vue.delete(state.dictionary[artboard.id].components, model.id)
      })
    },
    [types.MODEL_ACTIVATE](state, models) {
      if (empty(models)) {
        state.models.active = []
      } else {
        state.models.active = arrayWrap(models)
      }
    },
    [types.MODEL_UPDATE](_, { model, attribute, value }) {
      model.setInternalAttribute(attribute, value)
    },
    [types.MODELS_UPDATE](_, { models, attributes }) {
      models.forEach((model, index) => {
        const attr = Array.isArray(attributes) ? attributes[index] : attributes

        for (let key in attr) {
          model.setInternalAttribute(`_${key}`, attr[key])
        }
      })
    },
  },
  actions: {
    // This is an action and not a mutation so we can set the selected components where the action is consumed.
    // Setting them in the mutation creates undo / redo issues.
    [types.ARTBOARDS_COMPONENTS_PASTE]({ commit }, { artboard, models }) {
      models = models.map((m) => {
        return factory.create({
          ...m.toJSON(),
          ...{ id: uuid() },
        })
      })

      commit(types.MODEL_ADD, {
        artboard,
        model: models,
      })

      return { models }
    },
  },
  getters: {
    [types.ARTBOARDS_STATE]: (state) => {
      return state
    },
    [types.ARTBOARDS_ATTRIBUTE]: (state) => (key) => {
      return _get(state, key)
    },
    [types.ARTBOARDS_STATE]: (state) => {
      return state
    },
    [types.ARTBOARD_ACTIVE_MODELS]: (state) => {
       return state.dictionary[router.currentRoute.params.id]?.components || {}
    },
    [types.ARTBOARDS_MODEL_PROGRAM]: (state) => {
      const artboard = state.dictionary[router.currentRoute.params.id]

      if (! artboard?.components) {
        return undefined
      }

      return Object.values(artboard.components).find((v) => v.type === 'vgb-program')
    },
    [types.ARTBOARDS_MODEL_GEOMETRY]: (state) => {
      const artboard = state.dictionary[router.currentRoute.params.id]

      if (! artboard?.components) {
        return undefined
      }

      return Object.values(artboard.components).find((v) => v.type === 'vgb-geometry')
    }
  },
}

export default moduleArtboards
