import mixinArboards from '/@mixins/artboards'
import vComponents from './components/index.vue'
import mixinMotorcortex from '/@mixins/motorcortex'

export default {
  mixins: [mixinArboards, mixinMotorcortex],
  components: {
    'v-components': vComponents,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  methods: {
    getComponentStyleObject(component) {
      const pos = this.getComponentPosition(component)

      return {
        transform: `translate3d(${pos.x}px, ${pos.y}px, 0)`,
        zIndex: pos.z,
      }
    },
    getComponentPosition(model) {
      return {
        x: model.x.value,
        y: model.y.value,
        z: model.z.value,
      }
    },
  },
}
