import mixinArtboards from '/@mixins/artboards'
import mixinMotorcortex from '/@mixins/motorcortex'
import mixinComponent from '/@mixins/component'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'

export default {
  mixins: [mixinArtboards, mixinMotorcortex, mixinComponent],
  data() {
    return {
      showMenu: false,
      isMobile: false,
      menuWidth: 0,
    }
  },
  props: {
    artboardsVisible: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapStoreGetters(storeTypes.ARTBOARDS, ['dimensions.width']),
    activeScreenID() {
      return this.$route.params.id
    },
    currentScreenID() {
      return this.$route.params.id
    },
  },
  methods: {
    init() {
      this.initVariables()
      this.bindEvents()
      this.setIsMobile()
    },
    initVariables() {
      this.menuWidth = this.$refs.nav.getBoundingClientRect().width
    },
    bindEvents() {
      window.addEventListener('resize', this.setIsMobile)
    },
    setIsMobile() {
      this.isMobile = Math.min(window.innerWidth, this.dimensionsWidth) - this.menuWidth < 380
    },
    onClick(id) {
      if (id === this.currentScreenID) {
        return
      }

      this.$router.push({ name: 'screen', params: { id } }, () => {})
      this.toggleMenu()
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
  },
  mounted() {
    this.init()
  },
}
