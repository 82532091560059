import ModelArgument from '/@models/v-program/v-argument'
import ModelRoot from '/@models/v-program/v-elements/v-root'
import { programElementTypes, variableTypes, argumentTypes } from '/@shared/constants'
import { uuid, empty, namespace } from '/@/shared/utils'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import ModelStore from '../store';

export default class methodModel extends ModelStore {
  get isConstructor() {
    return this.title === 'constructor'
  }

  get constructorArguments() {
    return this.programModel.constructorArguments
  }

  get argumentVariables() {
    const argumentVariables = this.arguments
      .filter((a) => !empty(a.title))
      .map((a) => {
        return {
          type: variableTypes.ARGUMENT,
          id: a.id,
          title: a.title,
          isPoint: a.type === argumentTypes.POINT,
        }
      })

    return this.constructorArguments.concat(argumentVariables)
  }

  get setLocalVariables() {
    return this.elements
      .filter((e) => e.isSet)
      .filter((e) => e.isCreateVariable)
      .filter((e) => !empty(e.variable))
      .filter((e) => !e.variable.includes('['))
      .filter((e) => !e.variable.startsWith('this.'))
      .map((e) => {
        return {
          type: variableTypes.SET_LOCAL,
          id: e.id,
          title: e.variable,
        }
      })
  }

  get createVariables() {
    return this.elements
      .filter((e) => e.isCreate)
      .filter((e) => !empty(e.variable))
      .map((e) => {
        return {
          type: variableTypes.CREATE,
          id: e.id,
          title: e.variable,
        }
      })
  }

  get setGlobalVariables() {
    return this.programModel.variables
  }

  get geometryVariables() {
    return this.isPairedToGeometry ? this.geometryModel.variables : []
  }

  get variables() {
    return this.argumentVariables
      .concat(this.constructorArguments)
      .concat(this.setLocalVariables)
      .concat(this.createVariables)
      .concat(this.setGlobalVariables)
      .concat(this.geometryVariables)
      .filter((v) => {
        return v.title || v.title.length > 0
      })
  }

  get activeModels() {
    return vuexStore.getters[types.ARTBOARD_ACTIVE_MODELS]
  }

  get programModel() {
    if (this.jsGenerationProgramModel) {
      return this.jsGenerationProgramModel
    }

    return this.activeModels[this.programID]
  }

  get geometryModel() {
    if (this.jsGenerationGeometryModel) {
      return this.jsGenerationGeometryModel
    }

    return this.activeModels[this.geometryID]
  }

  get isPairedToProgram() {
    return !empty(this.programModel)
  }

  get isPairedToGeometry() {
    return !empty(this.geometryModel)
  }

  initVariables() {
    super.initVariables()

    this._programID = null
    this._geometryID = null
    this._jsGenerationGeometryModel = null
    this._jsGenerationProgramModel = null
    this._title = 'untitled'
    this._argumentList = []
    this._isDeletable = true
    this._isCollapsable = true
    this._isMenuVisible = true
    this._isActive = false
    this._elementTree = {
      id: uuid(),
      type: programElementTypes.root,
      isActive: true,
      children: [],
    }

    this._arguments = []
    this._elements = []
  }

  boot() {
    this.arguments = this.argumentList.map((a) => {
      return new ModelArgument(a)
    })

    this.elements = new ModelRoot({
      ...this.elementTree,
      ...{ programID: this.programID, methodID: this.id, geometryID: this.geometryID },
    })
  }

  addArgument(a) {
    this.arguments.push(new ModelArgument(a))
  }

  deleteArgument(a) {
    const index = this.arguments.findIndex((arg) => arg.is(a))

    if (index > -1) {
      this.arguments.splice(index, 1)
    }
  }

  getActualWaypointTitle(id, type) {
    return this.variables.find((v) => v.id === id).title
  }

  getVariableInfoObject(value, excludeID = null) {
    if (value.indexOf('[') > -1) {
      value = value.substring(0, value.indexOf('['))
    }

    const result = {
      type: null,
      title: null,
      id: null,
    }

    const sortIndex = [
      variableTypes.SET_LOCAL,
      variableTypes.CREATE,
      variableTypes.ARGUMENT,
      variableTypes.SET_GLOBAL,
      variableTypes.GEOMETRY,
    ]

    const variables = this.variables.sort((a, b) => {
      return sortIndex.indexOf(a.type) - sortIndex.indexOf(b.type)
    })

    const variable = variables.find((v) => {
      if (excludeID && v.id === excludeID) {
        return false
      }

      return v.title === value
    })

    if (variable) {
      result.type = variable.type
      result.title = variable.title
      result.id = variable.id
    } else {
      result.type = variableTypes.VALUE
      result.title = value
    }

    return result
  }

  hasVariable(id, type) {
    return this.variables.some((v) => v.id === id)
  }

  toJS(methodName = '', loadedProgramModels) {
    let isConstructor = false

    if (!empty(methodName)) {
      methodName = ` ${namespace(methodName)}`
      isConstructor = true
    } else {
      methodName = ''
    }

    const args = this.arguments.filter((a) => !empty(a.title))
    const argStr = args.map((a) => a.title).join(', ')


    const defStr = args
      .map((a) => a.toJS(isConstructor))
      .join('\n')

    const elStr = this.elements.toJS(loadedProgramModels)

    let pointStr = ''

    if (isConstructor) {
      pointStr = this.geometryVariables.reduce((c, v) => {
        return c + `this.${v.title} = [${v.cartPoseAbsolute.join(', ')}];\n`
      }, '')
    }

    return `function${methodName}(${argStr}) {
      ${pointStr}

      ${defStr}

      ${elStr}
    };
    `
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      isDeletable: this.isDeletable,
      isActive: this.isActive,
      argumentList: this.arguments.map((a) => a.toJSON()),
      elementTree: this.elements.toJSON(),
    }
  }
}
