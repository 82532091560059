import mixinArtboards from '/@mixins/artboards'
import { eventsGlobal } from '/@/constants/events'
import _get from 'lodash.get'
import { empty } from '/@/shared/utils'

export default {
  components: {
    parameters: () => import('./parameters/index.vue'),
    library: () => import('./library/index.vue'),
  },
  mixins: [mixinArtboards],
  data() {
    return {
      isLibraryActive: false,
      isParametersActive: false,
      input: {
        active: null,
        id: null,
      },
    }
  },
  computed: {
    isPanelActive() {
      return this.isLibraryActive || this.isParametersActive
    },
    panelTitle() {
      return this.isLibraryActive ? 'Library' : 'Parameters'
    },
    hasActiveInput() {
      return !empty(this.input.active)
    },
    areCorrectComponentsSelected() {
      return this.activeModels.map((m) => m.id).join('-') === this.input.id
    },
  },
  methods: {
    init() {
      this.$bus.$on(eventsGlobal.TOGGLE_LIBRARY_PANEL, this.toggleLibrary)
      this.$bus.$on(eventsGlobal.TOGGLE_PARAMETER_PANEL, this.toggleParameters)
      this.$bus.$on(eventsGlobal.SELECT_PATH_COMPONENT, this.selectPathComponent)
      this.$bus.$on(eventsGlobal.SELECT_PARAMETER, this.addPathToActiveInput)
    },
    toggleLibrary(event) {
      if (event && event.forceClose) {
        this.isLibraryActive = false
      } else {
        this.isLibraryActive = !this.isLibraryActive
      }

      this.isParametersActive = false
    },
    toggleParameters(event = null) {
      if (event && event.forceClose) {
        this.isParametersActive = false
      } else {
        this.isParametersActive = !this.isParametersActive
      }

      this.isLibraryActive = false
    },
    selectPathComponent(event) {
      this.isParametersActive = true
      this.isLibraryActive = false

      this.input.active = _get(event, 'input', null)
      this.input.id = _get(event, 'id', null)
    },
    addPathToActiveInput({ path }) {
      if (!this.hasActiveInput || !this.areCorrectComponentsSelected) {
        return
      }

      this.input.active.value = path
      this.input.active.dispatchEvent(
        new Event('input', {
          bubbles: true,
          cancelable: true,
        }),
      )
    },
  },
  mounted() {
    this.init()
  },
}
