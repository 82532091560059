import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelLight extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vLight
    this._title = 'light'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._label = {
      sortorder: 3,
      title: 'label',
      value: 'label',
      group: 'style',
      inputComponentType: inputComponentTypes.input,
    }

    this._fontSize = {
      sortorder: 4,
      title: 'font size',
      group: 'style',
      value: 15,
      settings: {
        min: 0,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._colorOn = {
      sortorder: 6,
      title: 'color on',
      group: 'value',
      value: colorScheme.turqoise,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._colorOff = {
      sortorder: 7,
      title: 'color off',
      value: 'transparent',
      group: 'value',
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 210,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._lineHeight = {
      sortorder: 1001,
      title: 'height',
      group: 'style',
      value: 15,
      settings: {
        min: 0,
      },
      inputComponentType: inputComponentTypes.number,
    }
  }
}
