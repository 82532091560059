export function matrix(data) {
  return {
    data,
    get(pos) {
      return this.data[pos[0]][pos[1]]
    },
    set(pos, value) {
      this.data[pos[0]][pos[1]] = value
    },
    toArray() {
      return this.data
    },
  }
}

export function multiply(m1, m2) {
  m1 = m1.toArray()
  m2 = m2.toArray()

  if (m1[0].length !== m2.length) {
    return
  }

  var result = []

  for (var i = 0; i < m1.length; i++) {
    result[i] = []

    for (var j = 0; j < m2[0].length; j++) {
      var sum = 0

      for (var k = 0; k < m1[i].length; k++) {
        sum += m1[i][k] * m2[k][j]
      }

      result[i][j] = sum
    }
  }

  return matrix(result)
}

export function transpose(m) {
  m = m.toArray()
  var result = []

  for (var i = 0; i < m[0].length; i++) {
    result[i] = []

    for (var j = 0; j < m.length; j++) {
      result[i][j] = m[j][i]
    }
  }

  return matrix(result)
}

export function matrixFromArray(arr) {
  return matrix(
    arr.map(value => {
      return [value]
    }),
  )
}
