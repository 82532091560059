import { types } from '/@vuex/types'
import { vueDeepSet } from '/@/shared/utils'
import _get from 'lodash.get'

const initialState = () => {
  return {
    show: true,
    snap: true,
    color: '#282f3a',
    dimensions: {
      width: 30,
      height: 30,
    },
  }
}

const moduleUnits = {
  state: {
    ...initialState(),
  },
  mutations: {
    [types.RASTER_LOAD](state, value) {
      state.dimensions.width = value.dimensions.width
      state.dimensions.height = value.dimensions.height
      state.show = value.show
      state.snap = value.snap
      state.color = value.color
    },
    [types.RASTER_ATTRIBUTE_UPDATE](state, payload) {
      vueDeepSet(state, payload.key, payload.value)
    },
  },
  getters: {
    [types.RASTER_STATE]: (state) => {
      return state
    },
    [types.RASTER_ATTRIBUTE]: (state) => (key) => {
      return _get(state, key)
    },
  },
}

export default moduleUnits
