import store from 'store'
import { autoSaveStorageKey } from '/@shared/config'
import mixinGrid from '/@mixins/grid'
import { gridSaveStatus } from '/@shared/constants'
import { empty } from '/@shared/utils'

export default {
  mixins: [mixinGrid],
  data() {
    return {
      isAutoSaveEnabled: true,
      saveTimeoutReference: null,
      savedTimeoutReference: null,
      delay: 1000,
      statusQueue: [],
      queueShiftTimerReference: null,
    }
  },
  watch: {
    isAutoSaveEnabled(value) {
      store.set(autoSaveStorageKey, value)
    },
    gridSaveStatus(value) {
      this.addStatusToQueue(value)
    },
    status() {
      if (this.isError) {
        this.$flits.error('Error saving grid file.')
      }
    },
  },
  computed: {
    isDefault() {
      return this.status === gridSaveStatus.DEFAULT
    },
    isSaving() {
      return this.status === gridSaveStatus.SAVING
    },
    isSaved() {
      return this.status === gridSaveStatus.SAVED
    },
    isError() {
      return this.status === gridSaveStatus.ERROR
    },
    status() {
      return this.statusQueue.length > 0 ? this.statusQueue[0] : gridSaveStatus.DEFAULT
    },
    gridSaveStatus() {
      return this.gridAttribute('saveStatus')
    },
  },
  methods: {
    initVariables() {
      const isAutoSaveEnabled = store.get(autoSaveStorageKey)

      if (empty(isAutoSaveEnabled) || isAutoSaveEnabled === true) {
        this.isAutoSaveEnabled = true
      } else {
        this.isAutoSaveEnabled = false
      }
    },
    addStatusToQueue(value) {
      if (value === gridSaveStatus.SAVING) {
        this.resetStatusQueue()
      }

      this.statusQueue.push(value)
      this.processStatusQueue()
    },
    processStatusQueue() {
      clearTimeout(this.queueShiftTimerReference)

      this.queueShiftTimerReference = setTimeout(() => {
        if (this.statusQueue.length > 1) {
          this.statusQueue.shift()
          this.processStatusQueue()
        }
      }, this.delay)
    },
    resetStatusQueue() {
      clearTimeout(this.queueShiftTimerReference)
      this.statusQueue = []
    },
    save() {
      this.gridManualSave()
    },
  },
  mounted() {
    this.initVariables()
  },
}
