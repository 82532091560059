import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelInput extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vInput
    this._title = 'input'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        prefix: '',
        isSlotRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._filter = {
      sortorder: 2,
      title: 'filter',
      icon: 'filter',
      inputComponentType: inputComponentTypes.filter,
      value: null,
      settings: {
        gain: 1,
        offset: 0,
        unit: '',
      },
    }

    this._step = {
      sortorder: 3,
      title: 'step',
      group: 'value',
      value: 0.5,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontSize = {
      sortorder: 4,
      title: 'font size',
      group: 'style',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontWeight = {
      sortorder: 5,
      title: 'font weight',
      group: 'style',
      value: 'normal',
      inputComponentType: inputComponentTypes.input,
    }

    this._lineHeight = {
      sortorder: 6,
      title: 'line height',
      group: 'style',
      value: 15,
      settings: {
        min: 0,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._decimals = {
      sortorder: 7,
      title: 'decimals',
      group: 'value',
      value: 2,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
        max: 100,
      },
    }

    this._min = {
      sortorder: 8,
      title: 'min',
      group: 'value',
      value: null,
      inputComponentType: inputComponentTypes.number,
    }

    this._max = {
      sortorder: 9,
      title: 'max',
      group: 'value',
      value: null,
      inputComponentType: inputComponentTypes.number,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 100,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
