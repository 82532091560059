import mixinMotorcortex from '/@mixins/motorcortex'
import { mixin as clickaway } from 'vue-clickaway'
import store from 'store'
import { defaultCommRequestPortSecure, defaultCommReceivePortSecure, connectionStorageKey } from '/@shared/config'

export default {
  mixins: [clickaway, mixinMotorcortex],
  data() {
    return {
      isPanelOpen: false,
      storageKey: connectionStorageKey,
      storageData: {
        host: '',
        portRequest: defaultCommRequestPortSecure,
        portReceive: defaultCommReceivePortSecure,
        username: '',
        password: '',
        secure: true,
      },
    }
  },
  computed: {
    host: {
      get() {
        return this.storageData.host
      },
      set(value) {
        store.set(this.storageKey + 'host', value)
      },
    },
    portRequest: {
      get() {
        return this.storageData.portRequest
      },
      set(value) {
        store.set(this.storageKey + 'portRequest', value)
      },
    },
    portReceive: {
      get() {
        return this.storageData.portReceive
      },
      set(value) {
        store.set(this.storageKey + 'portReceive', value)
      },
    },
    username: {
      get() {
        return this.storageData.username
      },
      set(value) {
        store.set(this.storageKey + 'username', value)
      },
    },
    password: {
      get() {
        return this.storageData.password
      },
      set(value) {
        store.set(this.storageKey + 'password', value)
      },
    },
    secure: {
      get() {
        return this.storageData.secure
      },
      set(value) {
        store.set(this.storageKey + 'secure', value)
      },
    },
  },
  methods: {
    togglePanelOpen() {
      if (this.isUserSelectingInput()) {
        return
      }

      this.isPanelOpen = !this.isPanelOpen
      this.setStorageData()
    },
    setStorageData() {
      if (this.isPanelOpen) {
        this.storageData = {
          host: store.get(this.storageKey + 'host', ''),
          portRequest: store.get(this.storageKey + 'portRequest', defaultCommRequestPortSecure),
          portReceive: store.get(this.storageKey + 'portReceive', defaultCommReceivePortSecure),
          username: store.get(this.storageKey + 'username', ''),
          password: store.get(this.storageKey + 'password', ''),
          secure: store.get(this.storageKey + 'secure', true),
        }
      }
    },
    onConnect() {
      window.location.reload()
    },
    isUserSelectingInput() {
      return document.activeElement.nodeName === 'INPUT'
    },
  },
}
