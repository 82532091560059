import ModelBase from './base'
import { inputComponentTypes, colorScheme, componentTypes } from '/@/shared/constants'

export default class ModelButton extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._title = 'button'
    this._type = componentTypes.vButton

    this._path = {
      sortorder: 1,
      title: 'path',
      value: '',
      settings: {
        slot: null,
        settings: '',
        prefix: '',
        isSlotRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._label = {
      sortorder: 3,
      title: 'label',
      value: 'label',
      group: 'style',
      inputComponentType: inputComponentTypes.textarea,
    }

    this._valueOn = {
      sortorder: 4,
      title: 'value on',
      group: 'value',
      value: 1,
      inputComponentType: inputComponentTypes.input,
    }

    this._valueOff = {
      sortorder: 5,
      title: 'value off',
      group: 'value',
      value: null,
      inputComponentType: inputComponentTypes.input,
    }

    this._sendingRate = {
      sortorder: 6,
      title: 'send rate',
      group: 'value',
      value: 0,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontSize = {
      sortorder: 7,
      title: 'font size',
      group: 'style',
      value: 15,
      settings: {
        min: 1,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._fontWeight = {
      sortorder: 8,
      group: 'style',
      title: 'font weight',
      options: [
        {
          title: 'normal',
          value: 'normal',
        },
        {
          title: 'bold',
          value: 'bold',
        },
      ],
      value: 'normal',
      inputComponentType: inputComponentTypes.radio,
    }

    this._color = {
      sortorder: 9,
      title: 'font color',
      group: 'style',
      value: colorScheme.white,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._backgroundColor = {
      sortorder: 10,
      title: 'background',
      group: 'style',
      value: colorScheme.green,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._align = {
      sortorder: 11,
      group: 'style',
      title: 'align',
      options: [
        {
          title: 'left',
          value: 'left',
        },
        {
          title: 'center',
          value: 'center',
        },
        {
          title: 'right',
          value: 'right',
        },
      ],
      value: 'center',
      inputComponentType: inputComponentTypes.radio,
    }

    this._sticky = {
      title: 'sticky',
      group: 'value',
      value: false,
      sortorder: 11,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 100,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 1,
      },
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 38,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 1,
      },
    }
  }
}
