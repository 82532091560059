import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'

export default class ModelElse extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.else
    this._title = 'else'
    this._icon = 'v-else'
    this._requiredPreviousSiblings = [programElementTypes.if, programElementTypes.elseIf]
    this._sortOrder = 2
    this._libraryGroup = libraryGroups.LOGIC
  }

  toJS(loadedProgramModels) {
    return `
      else {
        nop(new LineId("${this.id}"));
        ${this.children.map(c => c.toJS(loadedProgramModels)).join('\n')}
      }`
  }
}
