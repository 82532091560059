import * as tus from 'tus-js-client'
import { types, hookMutations } from '/@vuex/types'
import _debounce from 'lodash/debounce'
import vuexStore from '/@vuex/store'
import { gridSaveDelay, autoSaveStorageKey, maxUploadChunkSize } from '/@shared/config'
import http from '/@shared/http'
import session from '/@shared/session'
import router from '/@shared/router'
import _get from 'lodash.get'
import { gridSaveStatus } from '/@shared/constants'
import store from 'store'
import { empty } from '/@shared/utils'
import Vue from 'vue'

const state = {
  lastSavedGridString: '',
  saveTimeout: false,
  gridHash: null,
}

const updateGridSaveStatus = (value) => {
  vuexStore.commit(types.GRID_ATTRIBUTE_UPDATE, {
    key: 'saveStatus',
    value,
    saveMutation: false,
  })
}

const save = async (forceSave) => {
  const grid = vuexStore.getters[types.GRID]
  const gridString = JSON.stringify(grid)

  if (!forceSave) {
    if (gridString === state.lastSavedGridString || empty(grid.grid.version)) {
      return
    }
  }

  state.lastSavedGridString = gridString

  try {
    updateGridSaveStatus(gridSaveStatus.SAVING)

    await setGridHash()
    await updateGridResource(gridString)

    updateGridSaveStatus(gridSaveStatus.SAVED)
  } catch (error) {
    updateGridSaveStatus(gridSaveStatus.ERROR)
  } finally {
    await Vue.nextTick()
    updateGridSaveStatus(gridSaveStatus.DEFAULT)
  }
}

const setGridHash = () => {
  if (!empty(state.gridHash)) {
    return Promise.resolve()
  }

  return http
    .get('fs/frompath', {
      params: {
        path: session.get('grid'),
      },
    })
    .then((response) => {
      state.gridHash = response.data.data.hash
    })
}

const updateGridResource = (gridString) => {
  const data = new Blob([gridString], {
    type: 'application/json',
  })

  return new Promise((resolve, reject) => {
    const upload = new tus.Upload(data, {
      endpoint: APP_URL + 'upload/',
      chunkSize: maxUploadChunkSize,
      metadata: {
        filetype: 'grid',
        filename: 'grid',
      },
      retryDelays: [0, 1000, 3000, 8000],
      removeFingerprintOnSuccess: true,
      onSuccess: () => {
        const uploadID = upload.url.split('/').pop()

        http.put(`fs/files/${state.gridHash}`, { uploadID }).then(resolve).catch(reject)
      },
      onError: () => {
        reject(new Error('Could not save grid file.'))
      },
    })

    upload.start()
  })
}

const inspectMutation = (mutation) => {
  const isHookMutation = hookMutations.includes(mutation.type)
  const saveMutation = _get(mutation, 'payload.saveMutation', true)
  const isAutoSaveEnabled = store.get(autoSaveStorageKey)
  const isAutoSaveOn = empty(isAutoSaveEnabled) || isAutoSaveEnabled === true
  const isManualSave = mutation.type === types.GRID_MANUAL_SAVE
  const shouldSave = isAutoSaveOn ? isHookMutation && saveMutation : isManualSave
  const isAdmin = router.currentRoute.fullPath.includes('admin')

  if (isAdmin && shouldSave) {
    clearTimeout(state.saveTimeout)

    if (isManualSave) {
      save(isManualSave)
    } else {
      state.saveTimeout = setTimeout(() => {
        save()
      }, gridSaveDelay)
    }
  }
}

const pluginStorage = (vuexStore) => {
  const currentUrl = window.location.pathname
  const isAdmin = currentUrl.includes('admin') || currentUrl.includes('authenticate')

  if (isAdmin) {
    vuexStore.subscribe(inspectMutation)
  }
}
export default pluginStorage
