import mixinSettings from '/@mixins/settings'
import mixinArtboards from '/@mixins/artboards'
import collect from 'collect.js'
import cloneDeep from 'lodash.clonedeep'

export default {
  mixins: [mixinSettings, mixinArtboards],
  computed: {
    sharedProperties() {
      const props = collect(this.activeModels)
        .map((component) => {
          return collect(cloneDeep(component.getEditableAttributes()))
            .transform((attribute, key) => {
              attribute.name = attribute.title
              attribute.type = key
              return attribute
            })
            .values()
            .all()
        })
        .flatten(1)
        .mapToGroups((component) => [component.name, component])
        .filter((components) => components.length === this.activeModels.length)
        .map((components) => components[0])
        .map((component) => {
          if (!component.hasOwnProperty('group')) {
            component.group = component.title
          }

          return component
        })
        .filter((component) => component.inputComponentType !== null && component.inputComponentType !== undefined)
        .filter((c) => !c.hasOwnProperty('visible') || c.visible === true)
        .values()
        .groupBy('group')

      return props
    },
    nonGroupProperties() {
      return this.sharedProperties
        .keys()
        .filter((key) => {
          return !['position', 'style', 'value', 'visibility', 'path'].includes(key)
        })
        .transform((key) => {
          return this.sharedProperties.get(key).first()
        })
    },
    hasPathAttribute() {
      return this.sharedProperties.has('path')
    },
    hasVisibilityAttribute() {
      return this.sharedProperties.has('visibility')
    },
    hasValueAttributes() {
      return this.sharedProperties.has('value')
    },
    hasStyleAttributes() {
      return this.sharedProperties.has('style')
    },
    hasPositionAttributes() {
      return this.sharedProperties.has('position')
    },
    pathAtttribute() {
      return this.sharedProperties.get('path').first()
    },
    visibilityAttribute() {
      return this.sharedProperties.get('visibility').first()
    },
    valueAttributes() {
      return this.sharedProperties.get('value')
    },
    styleAttributes() {
      return this.sharedProperties.get('style')
    },
    positionAttributes() {
      return this.sharedProperties.get('position')
    },
    attributes() {
      const attributes = []

      if (this.hasPathAttribute) {
        attributes.push(this.pathAtttribute)
      }

      if (this.hasVisibilityAttribute) {
        attributes.push(this.visibilityAttribute)
      }

      this.nonGroupProperties.each((prop) => {
        attributes.push(prop)
      })

      return attributes
    },
    attributeSets() {
      const sets = []

      if (this.hasValueAttributes) {
        sets.push({
          title: 'value',
          attributes: this.valueAttributes,
        })
      }

      if (this.hasStyleAttributes) {
        sets.push({
          title: 'style',
          attributes: this.styleAttributes,
        })
      }

      if (this.hasPositionAttributes) {
        sets.push({
          title: 'position',
          attributes: this.positionAttributes,
        })
      }

      return sets
    },
  },
  methods: {
    sharedAttributeChanged(event) {
      this.modelsUpdate(this.activeModels, { [event.type]: event.value })
    },
    sharedAttributeSettingChanged({ attribute, type, value }) {
      this.modelsUpdate(this.activeModels, {
        [attribute]: {
          settings: {
            [type]: value,
          },
        },
      })
    },
  },
}
