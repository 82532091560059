import { inputComponentTypes, subscriptionScopes } from '/@/shared/constants'
import { isObject, empty } from '/@/shared/utils'
import Model from './model'
import _get from 'lodash.get'
import { useCommunication } from '/@composables/communication'

export default class ModelBase extends Model {
  initVariables() {
    super.initVariables()

    this._type = ''
    this._title = ''

    this._x = {
      title: 'x',
      group: 'position',
      inputComponentType: inputComponentTypes.number,
      sortorder: 1002,
      value: 0,
    }

    this._y = {
      title: 'y',
      group: 'position',
      inputComponentType: inputComponentTypes.number,
      sortorder: 1003,
      value: 0,
    }

    this._z = {
      title: 'z',
      group: 'position',
      inputComponentType: inputComponentTypes.number,
      sortorder: 1004,
      value: 0,
      settings: {
        min: 0,
        max: 1000,
      },
    }

    this._visibility = {
      title: 'visibility',
      value: '',
      inputComponentType: inputComponentTypes.path,
      sortorder: 2,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
      },
    }
  }

  getEditableAttributes() {
    const attributes = {}

    Object.keys(this).forEach((key) => {
      const attr = this[key]

      if (isObject(attr)) {
        attributes[key.slice(1)] = attr
      }
    })

    return attributes
  }

  getPathAttributes(subscriptionScope = subscriptionScopes.ARTBOARD) {
    const attributes = {}

    Object.keys(this).forEach((key) => {
      const attr = this[key]

      if (!isObject(attr)) {
        return
      }

      const isPathAttr = [inputComponentTypes.path, inputComponentTypes.break].includes(attr.inputComponentType)
      const attrSubscriptionScope = _get(attr, 'subscriptionScope', subscriptionScopes.ARTBOARD)
      const isDesiredScope = attrSubscriptionScope === subscriptionScope

      if (isPathAttr && isDesiredScope) {
        attributes[key.slice(1)] = attr
      }
    })

    return attributes
  }

  getPathValues(subscriptionScope = subscriptionScopes.ARTBOARD) {
    return Object.values(this.getPathAttributes(subscriptionScope))
      .map((attribute) => {
        return _get(attribute, 'settings.prefix', '') + attribute.value
      })
      .filter((value) => {
        return value.length > 0
      })
      .filter((value) => {
        return value.startsWith('root/')
      })
  }

  hasIllegalPathAttributes() {
    return Object.values(this.getPathAttributes())
      .filter((attr) => {
        return !empty(attr.value)
      })
      .some((attr) => {
        return !this.getPathAttributeIsValid(attr)
      })
  }

  getPathAttributeIsValid(attr) {
    const inputType = _get(attr, 'settings.inputType', 'path')

    if (inputType !== 'path') {
      return true
    }

    const path = _get(attr, 'settings.prefix', '') + attr.value
    const node = useCommunication().getTreeNodeFor(path)

    if (empty(node) || !empty(node.children)) {
      return false
    }

    if (!attr.hasOwnProperty('settings') || !attr.settings.hasOwnProperty('slot')) {
      return true
    }

    const sl = _get(attr, 'settings.slot', null)
    const isSlotRequired = _get(attr, 'settings.isSlotRequired', true)
    const slot = !empty(sl) ? Number.parseFloat(sl, 10) : null
    const nodeElements = Number.parseInt(node.info.numberOfElements, 10)

    if (slot !== null && slot >= nodeElements) {
      return false
    }

    if (nodeElements > 1 && slot === null && isSlotRequired) {
      return false
    }

    return true
  }

  hasEmptyPathAttribute = () => {
    if (this.hasOwnProperty('path')) {
      const isPathRequired = _get(this.path.settings, 'isRequired', true)
      const isPathEmpty = empty(this.path.value)

      if (isPathRequired && isPathEmpty) {
        return true
      }
    }

    return false
  }

  toJSON() {
    const result = {}
    const excludeProperties = ['title', 'lifeCycle', 'geometryModel']

    Object.keys(this)
      .map((key) => {
        return key.slice(1)
      })
      .filter((key) => {
        return !excludeProperties.includes(key)
      })
      .forEach((key) => {
        const value = this[key]

        if (isObject(value)) {
          result[key] = {
            value: value.value,
            ...(value.settings && {
              settings: value.settings,
            }),
          }
        } else {
          result[key] = this[key]
        }
      })

    return result
  }
}
