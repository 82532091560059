import Vue from 'vue'
import Vuex from 'vuex'
import storage from './plugins/storage'
import robotStorage from './plugins/robot/storage'
import { types } from '/@vuex/types'

import artboards from './modules/artboards'
import raster from './modules/raster'
import settings from './modules/settings'
import motorcortex from './modules/motorcortex'
import grid from './modules/grid'
import programs from './modules/programs'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  modules: {
    artboards,
    raster,
    settings,
    motorcortex,
    grid,
    programs,
  },
  plugins: [storage, robotStorage],
  actions: {
    [types.LOAD_GRID]({ commit }, grid) {
      commit(types.ARTBOARDS_LOAD, grid.artboards)
      commit(types.RASTER_LOAD, grid.raster)
      commit(types.MOTORCORTEX_LOAD, grid.motorcortex)
      commit(types.SETTINGS_LOAD, grid.settings)
      commit(types.GRID_LOAD, grid.grid)
    },
  },
  getters: {
    [types.GRID]: (state) => {
      return {
        artboards: {
          order: state.artboards.order,
          counter: state.artboards.counter,
          dictionary: state.artboards.dictionary,
          startID: state.artboards.startID,
          colors: {
            foreground: state.artboards.colors.foreground,
            background: state.artboards.colors.background,
          },
          dimensions: {
            width: state.artboards.dimensions.width,
            height: state.artboards.dimensions.height,
          },
        },
        raster: {
          dimensions: {
            width: state.raster.dimensions.width,
            height: state.raster.dimensions.height,
          },
          show: state.raster.show,
          snap: state.raster.snap,
          color: state.raster.color,
        },
        grid: {
          title: state.grid.title,
          version: state.grid.version,
        },
        motorcortex: {
          connection: {
            login: state.motorcortex.connection.login,
            secure: state.motorcortex.connection.secure,
            open: false,
            hosts: {
              general: state.motorcortex.connection.hosts.general,
              deployment: state.motorcortex.connection.hosts.deployment,
            },
            ports: {
              request: state.motorcortex.connection.ports.request,
              receive: state.motorcortex.connection.ports.receive,
            },
            user: {
              name: state.motorcortex.connection.user.name,
              password: state.motorcortex.connection.user.password,
            },
          },
        },
        settings: {
          timePath: state.settings.timePath,
          error: {
            file: state.settings.error.file,
            definition: {
              folder: state.settings.error.definition.folder,
            },
            paths: {
              acknowledge: state.settings.error.paths.acknowledge,
              subscription: state.settings.error.paths.subscription,
            },
            frequencyDivider: state.settings.error.frequencyDivider,
          },
          state: {
            file: state.settings.state.file,
            paths: {
              subscription: state.settings.state.paths.subscription,
            },
            frequencyDivider: state.settings.state.frequencyDivider,
          },
          log: {
            paths: {
              subscription: state.settings.log.paths.subscription,
            },
          },
          application: {
            fitToScreen: state.settings.application.fitToScreen,
          }
        },
      }
    },
  },
})

export default store
