import { types } from '/@vuex/types'
import _get from 'lodash.get'

const modulePrograms = {
  state: {
    programs: [],
  },
  mutations: {
    [types.PROGRAMS_UPDATE](state, value) {
      state.programs = value
    },
  },
  getters: {
    [types.PROGRAMS]: state => {
      return state.programs
    },
  },
}

export default modulePrograms
