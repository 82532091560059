import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelRange extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vRange
    this._title = 'range'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._filter = {
      sortorder: 2,
      title: 'filter',
      icon: 'filter',
      inputComponentType: inputComponentTypes.filter,
      value: null,
      settings: {
        gain: 1,
        offset: 0,
        unit: '',
      },
    }

    this._label = {
      sortorder: 3,
      title: 'label',
      group: 'style',
      value: '',
      inputComponentType: inputComponentTypes.input,
    }

    this._min = {
      sortorder: 4,
      title: 'min',
      group: 'value',
      value: 0,
      inputComponentType: inputComponentTypes.number,
    }

    this._max = {
      sortorder: 5,
      title: 'max',
      group: 'value',
      value: 100,
      inputComponentType: inputComponentTypes.number,
    }

    this._step = {
      sortorder: 6,
      title: 'step',
      group: 'value',
      value: 1,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._decimals = {
      sortorder: 7,
      title: 'decimals',
      group: 'value',
      value: 2,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
        max: 100,
      },
    }

    this._updateOn = {
      sortorder: 9,
      title: 'update on',
      group: 'value',
      options: [
        {
          title: 'move',
          value: 'move',
        },
        {
          title: 'release',
          value: 'release',
        },
      ],
      value: 'move',
      inputComponentType: inputComponentTypes.radio,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 250,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
