import { empty, arraysContainSameValues } from '/@shared/utils'
import { SessionState } from '@vectioneer/motorcortex-js'
import SubscribeJob from './jobs/subscribe'
import UnsubscribeJob from './jobs/unsubscribe'
import EventEmitter from 'events'
import SubscriptionData from './data'

export default class BaseSubscription extends EventEmitter {
  constructor(communication, callback = null, subscriptionData = null) {
    super()

    this.sessionManager = communication.sessionManager
    this.subscriptionManager = communication.subscriptionManager
    this.callback = callback
    this.subscription = null
    this.connectionObserverID = null

    this.messages = []
    this.currentSubscriptionData = []
    this.currentFrequencyDivider = 1
    this.currentSubscriptionParameters = []

    this.postponedSubscriptionData = null
    this.queue = []
    this.isQueueProcessing = false

    this.initVariables()
    this.observeConnection()
    this.subscribe(subscriptionData)
  }

  get isSubscribed() {
    return !empty(this.currentSubscriptionData)
  }

  get isConnected() {
    return this.sessionManager.state === SessionState.CONNECTION_OK
  }

  get hasQueueJobs() {
    return this.queue.length > 0
  }

  initVariables() {}

  observeConnection() {
    this.connectionObserverID = this.sessionManager.notify(({ state }) => {
      switch (state) {
        case SessionState.CONNECTION_OK:
          this.subscribe(this.postponedSubscriptionData)

          break
        case SessionState.CONNECTION_LOST:
          if (!this.postponedSubscriptionData) {
            this.postponedSubscriptionData = this.currentSubscriptionData
          }

          this.subscription = null
          this.reset()
          break
      }
    })
  }

  async subscribe(data) {
    if (!this.isConnected) {
      return (this.postponedSubscriptionData = data)
    }

    if (!(data instanceof SubscriptionData)) {
      return
    }

    if (!this.shouldSubscribe(data)) {
      return
    }

    this.queue.push(new UnsubscribeJob(this))

    if (data.isValid()) {
      this.queue.push(new SubscribeJob(this, data))
    }

    this.processQueue()
  }

  async unsubscribe() {
    if (!this.isSubscribed) {
      return
    }

    this.queue.push(new UnsubscribeJob(this))
    this.processQueue()
  }

  async processQueue() {
    if (!this.hasQueueJobs || this.isQueueProcessing) {
      return
    }

    this.isQueueProcessing = true

    this.queue
      .shift()
      .run()
      .then(() => {
        this.isQueueProcessing = false
        this.processQueue()
      })
      .catch((error) => {
        this.emit('error', error)
        this.isQueueProcessing = false
      })
  }

  updateSubscriptionData(data) {
    this.currentSubscriptionData = data
    this.currentSubscriptionParameters = data.getValidParameters()
    this.currentFrequencyDivider = data.frequencyDivider
  }

  updateSubscription(subscription) {
    this.subscription = subscription
    this.subscription.notify((messages) => {
      this.processMessages(messages)
    })
  }

  reset() {
    this.currentSubscriptionData = null
    this.currentFrequencyDivider = 1
    this.currentSubscriptionParameters = []
    this.messages = []
  }

  processMessages(messages) {
    if (!Array.isArray(messages) || messages.length === 0) {
      return
    }

    this.messages = messages

    if (this.callback) {
      return this.callback(messages)
    }
  }

  shouldSubscribe(data) {
    const isGroupNameActive = this.currentSubscriptionData?.groupName === data.groupName
    const areParametersSubscribed = arraysContainSameValues(
      this.currentSubscriptionParameters,
      data.getValidParameters(),
    )

    if (isGroupNameActive && areParametersSubscribed) {
      return false
    }

    const job = this.queue.find((j) => j.data.groupName === data.groupName)

    if (job) {
      const areParametersInJobData = arraysContainSameValues(job.data.getValidParameters(), data.getValidParameters())

      if (areParametersInJobData) {
        return false
      }
    }

    return true
  }

  getPathIndex(path) {
    return this.currentSubscriptionParameters.indexOf(path)
  }

  getPathValue(path) {
    const index = this.getPathIndex(path)
    const isEmptyMessages = empty(this.messages)
    const isIndexValid = index > -1 && index < this.messages.length

    if (isEmptyMessages || !isIndexValid) {
      return
    }

    return this.messages[index].value
  }

  destroy() {
    this.unsubscribe()
    this.sessionManager.remove(this.connectionObserverID)
  }
}
