import { types } from '/@vuex/types'
import { mapGetters } from 'vuex'

export default {
  methods: {
    settingsUpdateAttribute(key, value) {
      this.$store.commit(types.SETTINGS_ATTRIBUTE_UPDATE, {
        key,
        value,
      })
    },
  },
  computed: {
    ...mapGetters({
      settings: types.SETTINGS_STATE,
      settingsAttribute: types.SETTINGS_ATTRIBUTE,
    }),
  },
}
