import { types } from '/@vuex/types'
import { mapMutations, mapGetters } from 'vuex'
import { empty } from '/@/shared/utils'
import collect from 'collect.js'

export default {
  methods: {
    ...mapMutations({
      artboardsAdd: types.ARTBOARDS_ADD,
      artboardsDelete: types.ARTBOARDS_DELETE,
      artboardsUpdateArtboardAttribute: types.ARTBOARDS_UPDATE_ARTBOARD_ATTRIBUTE,

      modelAdd: types.MODEL_ADD,
      modelDelete: types.MODEL_DELETE,
      modelActivate: types.MODEL_ACTIVATE,
    }),
    modelsUpdate(models, attributes) {
      return this.$store.commit(types.MODELS_UPDATE, {
        models,
        attributes,
      })
    },
    artboardsComponentsPaste(payload) {
      return this.$store.dispatch(types.ARTBOARDS_COMPONENTS_PASTE, payload)
    },
    artboardsUpdateAttribute(key, value) {
      this.$store.commit(types.ARTBOARDS_ATTRIBUTE_UPDATE, {
        key,
        value,
      })
    },
    redirectToFirstArtboard() {
      const artboardUUIDs = Object.keys(this.artboards.dictionary)

      this.$router.push(
        {
          name: 'artboard',
          params: {
            id: artboardUUIDs[0],
          },
        },
        () => {},
      )
    },
    findComponent(componentID) {
      return this.allComponents.first((comp) => {
        return comp.id === componentID
      })
    },
    findComponentModel(componentID) {
      return this.componentModels.find((model) => model.id === componentID)
    },
  },
  computed: {
    ...mapGetters({
      artboards: types.ARTBOARDS_STATE,
    }),
    componentModels() {
      return Object.values(this.artboardActive.components)
    },
    activeModels() {
      return this.artboards.models.active
    },
    artboardActive() {
      if (this.artboards.dictionary.hasOwnProperty(this.$route.params.id)) {
        return this.artboards.dictionary[this.$route.params.id]
      }

      return null
    },
    isArtboardActive() {
      return !empty(this.artboardActive)
    },
    allComponents() {
      return collect(Object.values(this.artboards.dictionary))
        .pluck('components')
        .map((obj) => Object.values(obj))
        .flatten(1)
    },
    artboardsFirstScreenID() {
      return this.artboards.startID
    },
  },
}
