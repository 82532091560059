import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelSwitch extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vSwitch
    this._title = 'switch'

    this._path = {
      sortorder: 1,
      title: 'path',
      value: '',
      settings: {
        slot: null,
        prefix: '',
        isSlotRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._valueOn = {
      sortorder: 3,
      title: 'value on',
      group: 'value',
      inputComponentType: inputComponentTypes.input,
      value: 1,
    }

    this._valueOff = {
      sortorder: 4,
      title: 'value off',
      group: 'value',
      inputComponentType: inputComponentTypes.input,
      value: 0,
    }
  }
}
