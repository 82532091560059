import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelOutput extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vOutput
    this._title = 'output'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
        isSlotRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._map = {
      sortorder: 3,
      group: 'value',
      title: 'map',
      icon: 'file',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._unit = {
      sortorder: 4,
      group: 'style',
      title: 'unit',
      value: '',
      inputComponentType: inputComponentTypes.input,
    }

    this._decimals = {
      sortorder: 5,
      group: 'value',
      title: 'decimals',
      value: 2,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
        max: 100,
      },
    }

    this._toHex = {
      sortorder: 3,
      group: 'value',
      title: 'hexadecimal',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._color = {
      sortorder: 6,
      group: 'style',
      title: 'color',
      value: colorScheme.white,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._fontSize = {
      sortorder: 7,
      group: 'style',
      title: 'font size',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontWeight = {
      sortorder: 7,
      group: 'style',
      title: 'font weight',
      value: 'normal',
      inputComponentType: inputComponentTypes.input,
    }

    this._align = {
      sortorder: 8,
      group: 'style',
      title: 'align',
      options: [
        {
          title: 'left',
          value: 'left',
        },
        {
          title: 'center',
          value: 'center',
        },
        {
          title: 'right',
          value: 'right',
        },
      ],
      value: 'right',
      inputComponentType: inputComponentTypes.radio,
    }

    this._lineHeight = {
      sortorder: 9,
      group: 'style',
      title: 'line height',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._width = {
      group: 'position',
      sortorder: 11,
      title: 'width',
      value: 50,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
