import _get from 'lodash.get'
import factory from '/@models/factory'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'

export default class GridImporter {
  constructor(gridFile) {
    this.gridFile = gridFile
  }

  get appVersion() {
    return vuexStore.getters[types.SETTINGS_STATE].gridVersion
  }

  get isNewFile() {
    return this.gridFile.grid?.version === ''
  }

  get gridVersion() {
    return this.gridFile.grid?.version
  }

  get isDotVersion() {
    return typeof this.gridVersion === 'string' && this.gridVersion.includes('.')
  }

  get hasVersion() {
    return this.gridVersion !== null && this.gridVersion !== undefined
  }

  get isVersionLowerThenAppVersion() {
    return Number.parseInt(this.gridVersion, 10) < this.appVersion
  }

  get isVersionHigherThenAppVersion() {
    return Number.parseInt(this.gridVersion, 10) > this.appVersion
  }

  get isOutdatedVersion() {
    return !this.hasVersion || this.isDotVersion || this.isVersionLowerThenAppVersion
  }

  get isOutdatedApp() {
    return !this.isOutdatedVersion && this.isVersionHigherThenAppVersion
  }

  import() {
    return new Promise(async (resolve) => {
      try {
        const data = await this.parse()
        this.addModelsToArtboard(data)

        resolve(data)
      } catch (e) {
        throw e
      }
    })
  }

  parse() {
    const data = this.gridFile

    return new Promise(async (resolve, reject) => {
      if (this.isNewFile) {
        data.grid.version = this.appVersion
        return resolve(data)
      }

      if (!this.hasVersion || this.isDotVersion || this.isVersionLowerThenAppVersion) {
        const Parser = (await import('/@parsers/grid/parser')).default

        new Parser()
          .parse(data)
          .then(resolve)
          .catch(() => reject('Could not parse grid file.'))
      } else {
        resolve(data)
      }
    })
  }

  addModelsToArtboard(data) {
    for (let dictKey in data.artboards.dictionary) {
      const artboard = data.artboards.dictionary[dictKey]

      for (let compKey in artboard.components) {
        const compData = artboard.components[compKey]
        artboard.components[compKey] = factory.create(compData)
      }
    }

    return data
  }
}
