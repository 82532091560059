import ModelPoint from './v-point'

export default class ModelRootPoint extends ModelPoint {
  initVariables() {
    super.initVariables()

    this._programID = null
    this._geometryID = null
    this._points = null
    this._jsGenerationGeometryModel = null
    this._jsGenerationProgramModel = null
  }
}
