import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'
import { randomString } from '/@shared/utils'

export default class ModelLoop extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.loop
    this._title = 'loop'
    this._icon = 'v-loop'
    this._amount = '∞'
    this._sortOrder = 0
    this._libraryGroup = libraryGroups.FLOW
  }

  toJS(loadedProgramModels) {
    if (this.amount === '∞' || this.amount === '') {
      return `
        nop(new LineId("${this.id}"));

        while(true){
          nop(new LineId("${this.id}"));
          ${this.children.map(c => c.toJS(loadedProgramModels)).join('\n')}
        }`
    } else {
      const i = randomString()

      return `
        nop(new LineId("${this.id}"));

        for(var ${i} = 0; ${i} < ${this.amount}; ${i} ++) {
          nop(new LineId("${this.id}"));
          ${this.children.map(c => c.toJS(loadedProgramModels)).join('\n')}
        }`
    }
  }
}
