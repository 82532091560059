import { computed } from 'vue'
import { empty } from '../shared/utils'
import { communication } from '/@plugins/communication/communication'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'

export type TypeInfo = {
  id: number
  dataType: number
  dataSize: number
  numberOfElements: number
  flags: number
  path: string
  permissions: number
  paramType: string
  groupId: string
  unit: string
}

export const useCommunication = () => {
  const isConnected = computed(() => {
    return vuexStore.getters[types.MOTORCORTEX_ATTRIBUTE]('connection.open')
  })

  const getTreeNodeFor = (path: string) => {
    let result = null

    if (!isConnected.value) {
      return result
    }

    try {
      result = communication.getParameterTree().getChild(path)
    } catch (e) {
      result = null
    }

    return result
  }

  const isPathValid = (path: string) => {
    if (empty(path)) {
      return false
    }

    const node = getTreeNodeFor(path)
    return !empty(node) && empty(node.children)
  }

  const setParameter = (path: string, values:any, settings: any = {}) => {
    return communication.setParameter(path, values, settings)
  }

  const closeConnection = () => {
    return communication.sessionManager.close()
  }

  const dataTypes = communication.messageTypes.DataType

  const getDataType = (dataType: number) => {
    return Object.keys(dataTypes).find((key) => dataTypes[key] === dataType)
  }

  const getDataTypeForPath = (path: string) => {
    return getDataType(getTreeNodeFor(path).info.dataType)
  }

  const getTypeInfoForPath = (path: string): TypeInfo | undefined => {
    return getTreeNodeFor(path)?.info
  }

  return {
    isConnected,
    getTreeNodeFor,
    setParameter,
    isPathValid,
    closeConnection,
    getDataTypeForPath,
    getTypeInfoForPath,
    getDataType,
  }
}
