import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'
import { variableTypes, valueTypes } from '/@models/v-program/v-elements/v-element'

export default class ModelElseIf extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.elseIf
    this._title = 'else if'
    this._icon = 'v-elseif'
    this._requiredPreviousSiblings = [programElementTypes.if]
    this._variable = ''
    this._operator = ''
    this._value = ''
    this._variableObjectID = null
    this._valueObjectID = null
    this._sortOrder = 1
    this._libraryGroup = libraryGroups.LOGIC
  }

  toJS(loadedProgramModels) {
    const variable = this.incorporateVariableGetCommand()
    const value = this.incorporateValueGetCommand()

    return `
      else if(${variable} ${this.operator} ${value}){
        nop(new LineId("${this.id}"));
        ${this.children.map(c => c.toJS(loadedProgramModels)).join('\n')}
      }`
  }

  incorporateVariableGetCommand() {
    return this.variableType === variableTypes.PARAMETER //
      ? `get("${this.variable}")`
      : this.variable
  }

  incorporateValueGetCommand() {
    return this.valueType === valueTypes.PARAMETER //
      ? `get("${this.value}")`
      : this.value
  }
}
