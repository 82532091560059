import _intersection from 'lodash/intersection'
import _throttle from 'lodash/throttle'
import { extractTouch } from '/@shared/utils'
import collect from 'collect.js'

export default {
  data() {
    return {
      componentDownTimer: null,
      mouseStartPosition: {},
      selectionRectangle: {},
      artboardRectangle: {},
      componentData: [],
      activeComponent: {},
      lastMouseMoveEvent: null,
    }
  },
  computed: {
    isDragging() {
      return this.lastMouseMoveEvent !== null
    },
  },
  methods: {
    initDrag() {
      this.componentMouseMoveListerFunction = _throttle(this.componentMouseMoveLister, 10)
    },
    componentMouseDownListener(event, component) {
      event = extractTouch(event)

      if (this.isComponentClicked(event)) {
        this.selectComponent(component)
        this.setMouseStartPosition(event)
        this.setSelectionRectangle()
        this.setArtboardRectangle()
        this.setComponentData()
        this.activateDrag()
        this.addMouseUpListener()
      }
    },
    componentMouseUpListener(event) {
      event = extractTouch(event)

      if (this.isDragging) {
        this.commitNewPositions()
      } else {
        this.selectComponentInCaseOfNoDrag(event)
      }

      this.deActivateDrag()
      this.removeMouseUpListener()
      this.resetVariables()
    },
    componentMouseMoveLister(event) {
      this.lastMouseMoveEvent = extractTouch(event)

      const { deltaX, deltaY } = this.deltaMouse(this.lastMouseMoveEvent)

      this.componentData.forEach(({ model, element }) => {
        const position = this.incorporateGridSnap(model.x.value + deltaX, model.y.value + deltaY)
        const { x, y } = this.getMinimumPosition(position)

        element.style.transform = `translate3d(${x}px, ${y}px, 0)`
      })
    },
    activateDrag() {
      clearTimeout(this.componentDownTimer)

      this.componentDownTimer = setTimeout(() => {
        this.deActivateDrag()
        window.addEventListener('mousemove', this.componentMouseMoveListerFunction)
        window.addEventListener('touchmove', this.componentMouseMoveListerFunction)
      }, 100)
    },
    deActivateDrag() {
      window.removeEventListener('mousemove', this.componentMouseMoveListerFunction)
      window.removeEventListener('touchmove', this.componentMouseMoveListerFunction)
    },
    addMouseUpListener() {
      this.removeMouseUpListener()
      window.addEventListener('mouseup', this.componentMouseUpListener)
      window.addEventListener('touchend', this.componentMouseUpListener)
    },
    removeMouseUpListener() {
      window.removeEventListener('mouseup', this.componentMouseUpListener)
      window.removeEventListener('touchend', this.componentMouseUpListener)
    },
    selectComponentInCaseOfNoDrag(event) {
      const isShiftPressed = this.isShiftPressed
      const isComponentClicked = this.isComponentClicked(event)

      if (!isComponentClicked || this.isDragging || isShiftPressed) {
        return
      }

      this.modelActivate(this.activeComponent)
    },
    selectComponent(component) {
      this.activeComponent = component

      const isComponentSelected = this.activeModels.map((m) => m.id).includes(component.id)

      let selectedModels = []

      if (!isComponentSelected) {
        selectedModels = [component]
      }

      if (this.isShiftPressed) {
        if (isComponentSelected) {
          selectedModels = this.activeModels.filter((m) => m.id !== component.id)
        } else {
          selectedModels = this.activeModels.concat([component])
        }
      }

      if (selectedModels.length > 0) {
        document.activeElement.blur()
        this.modelActivate(selectedModels)
      }

      document.activeElement.blur()
    },
    commitNewPositions() {
      if (!this.lastMouseMoveEvent) {
        return
      }

      const { deltaX, deltaY } = this.deltaMouse(this.lastMouseMoveEvent)

      if (deltaX + deltaY === 0) {
        return
      }

      const models = this.componentData.map(({ model }) => model)
      const attributes = this.componentData.map(({ model }) => {
        let x = Number.parseFloat(model.x.value) + Number.parseFloat(deltaX)
        let y = Number.parseFloat(model.y.value) + Number.parseFloat(deltaY)
        return this.getMinimumPosition(this.incorporateGridSnap(x, y))
      })

      this.modelsUpdate(models, attributes)
    },
    setMouseStartPosition(event) {
      this.mouseStartPosition.x = event.clientX
      this.mouseStartPosition.y = event.clientY
    },
    setComponentData() {
      this.componentData = this.activeModels.map((m, i) => {
        return {
          model: m,
          element: document.querySelector(`#component${m.id}`),
        }
      })
    },
    setSelectionRectangle() {
      const components = this.activeModels.map((m) => `#component${m.id}`)
      const elements = document.querySelectorAll(components)
      const rectangles = collect(Array.from(elements).map((e) => e.getBoundingClientRect()))

      this.selectionRectangle = {
        x: rectangles.min('left'),
        y: rectangles.min('top'),
        width: rectangles.max('right') - rectangles.min('left'),
        height: rectangles.max('bottom') - rectangles.min('top'),
      }
    },
    setArtboardRectangle() {
      this.artboardRectangle = document.querySelector('.components').getBoundingClientRect()
    },
    deltaMouse(event) {
      return {
        deltaX: event.clientX - this.mouseStartPosition.x,
        deltaY: event.clientY - this.mouseStartPosition.y,
      }
    },
    incorporateGridSnap(x, y) {
      if (this.raster.snap) {
        const halfGridWidth = this.raster.dimensions.width * 0.5
        const halfGridHeight = this.raster.dimensions.height * 0.5

        x = Math.round(x / halfGridWidth) * halfGridWidth
        y = Math.round(y / halfGridHeight) * halfGridHeight
      }

      return { x, y }
    },
    getMinimumPosition(position) {
      return {
        x: Math.max(position.x, 0),
        y: Math.max(position.y, 0),
      }
    },
    isComponentClicked(event) {
      return _intersection(Array.from(event.target.classList), ['artboard', 'scale-handle']).length === 0
    },
    resetVariables() {
      this.lastMouseMoveEvent = null
      this.mouseStartPosition = {}
    },
  },
  mounted() {
    this.initDrag()
  },
}
