import ModelBase from './base'
import { inputComponentTypes, componentTypes, subscriptionScopes } from '/@/shared/constants'

export default class ModelCamera extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vCamera
    this._title = 'camera'

    this.subscriptonGroupName = {
      sortorder: 2,
      title: 'subscription group',
      group: 'value',
      inputComponentType: inputComponentTypes.input,
      value: '',
    }

    this._pathSize = {
      sortorder: 2,
      title: 'camera size',
      group: 'value',
      subscriptionScope: subscriptionScopes.COMPONENT,
      inputComponentType: inputComponentTypes.path,
      value: '',
    }

    this._pathResolution = {
      sortorder: 3,
      title: 'camera resolution',
      group: 'value',
      subscriptionScope: subscriptionScopes.COMPONENT,
      inputComponentType: inputComponentTypes.path,
      value: '',
    }

    this._pathImage = {
      sortorder: 4,
      title: 'camera image',
      group: 'value',
      subscriptionScope: subscriptionScopes.COMPONENT,
      inputComponentType: inputComponentTypes.path,
      value: '',
    }

    this._frequencyDivider = {
      sortorder: 5,
      title: 'frequency divider',
      group: 'value',
      value: 1,
      settings: {
        decimals: 0,
        min: 1,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._showFps = {
      sortorder: 7,
      title: 'show FPS',
      group: 'style',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 200,
      inputComponentType: inputComponentTypes.number,
    }
  }
}
