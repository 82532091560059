import mixinResource from '/@mixins/resource'
import session from '/@shared/session'
import GridImporter from '/@/importers/grid'
import { types } from '/@vuex/types'

export default {
  mixins: [mixinResource],
  props: {
    continueParsing: {
      type: Function,
    },
  },
  data() {
    return {
      isFormVisible: false,
      isCreatingFile: false,
      fileName: '',
    }
  },
  computed: {
    gridFileName() {
      return this.fileName.endsWith('.grid') //
        ? this.fileName
        : this.fileName + '.grid'
    },
    isFileNameValid() {
      return this.fileName.length > 1
    },
  },
  methods: {
    overwriteFile() {
      this.continueParsing()
      this.closeModal()
    },
    toggleFormVisibility() {
      this.isFormVisible = !this.isFormVisible
    },
    async createFile() {
      this.toggleIsCreatingFile()

      this.isGridNameAvailable()
        .then(() => {
          this.getNewGridFile().then(this.redirectToNewGridFile).catch(this.handleError)
        })
        .catch(this.handleError)
    },

    getNewGridFile() {
      return new Promise(async (resolve, reject) => {
        this.downloadResourceFromPath(this.gridPath)
          .then((response) => {
            new GridImporter(response.data)
              .parse()
              .then((data) => {
                this.createResource(this.gridFileName, data)
                  .then((respone) => resolve(respone.data.data))
                  .catch(reject)
              })
              .catch(reject)
          })
          .catch(reject)
      })
    },
    toggleIsCreatingFile() {
      this.isCreatingFile = !this.isCreatingFile
    },
    handleError(message) {
      this.$flits.error(message)
      this.isCreatingFile = false
    },
    redirectToNewGridFile(file) {
      session.set('grid', decodeURIComponent(file.path))
      window.location.reload()
    },
    isGridNameAvailable() {
      return new Promise((resolve, reject) => {
        this.searchFromPath(this.gridFileName)
          .then(() => {
            reject(`A file named ${this.gridFileName} already exists.`)
          })
          .catch(resolve)
      })
    },
    closeModal() {
      this.$dialog.close('parserMessageDialog')
    },
  },
}
