import mixinMotorcortex from '/@mixins/motorcortex'
import { storeTypes, mapStoreGetters } from '/@/vuex/helpers'

export default {
  mixins: [mixinMotorcortex],
  computed: {
    ...mapStoreGetters(storeTypes.MOTORCORTEX, [
      'connection.hosts.general',
      'connection.hosts.deployment',
      'connection.login',
      'connection.ports.request',
      'connection.ports.receive',
      'connection.user.name',
      'connection.user.password',
      'connection.secure',
    ]),
  },
  methods: {
    onConnect() {
      window.location.reload()
    },
  },
}
