import Model from './model'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import { empty } from '/@shared/utils'

export default class ModelStore extends Model {
  get root() {
    return this.getRoot()
  }

  get programID() {
    return this.root.programID
  }

  get methodID() {
    return this.root.methodID
  }

  get geometryID() {
    return this.root.geometryID
  }

  get activeModels() {
    return vuexStore.getters[types.ARTBOARD_ACTIVE_MODELS]
  }

  get programModel() {
    if (this.root.jsGenerationProgramModel) {
      return this.root.jsGenerationProgramModel
    }

    return this.activeModels[this.programID]
  }

  get geometryModel() {
    if (this.root.jsGenerationGeometryModel) {
      return this.root.jsGenerationGeometryModel
    }

    return this.activeModels[this.geometryID]
  }

  get methodModel() {
    if (empty(this.programModel)) {
      return
    }

    return this.programModel.methods
      .concat(this.programModel.main)
      .find((method) => method.id === this.methodID)
  }

  get isPairedToGeometry() {
    return !empty(this.geometryModel)
  }

  get isPairedToProgram() {
    return !empty(this.programProgram)
  }
}
