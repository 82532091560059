export const defaultErrorFile = '/common/errors/errors.json'
export const defaultStateFile = '/common/states/states.json'
export const defaultErrorDefFolder = '/common/errors/defs'

export const defaultErrorPath = 'root/Logic/activeErrors'
export const defaultStatePath = 'root/Logic/state'
export const defaultLogPath = 'root/logger/logOut'
export const defaultErrorAcknowledgePath = 'root/Logic/guiFaultAcknowledge'

export const defaultCommRequestPortSecure = 5568
export const defaultCommReceivePortSecure = 5567

export const defaultCommRequestTimeout = 2000
export const defaultCommTreeTimeout = 10000
export const defaultFrequencyDivider = 100

export const defaultUserName = 'root'
export const defaultUserPassword = 'vectioneer'
export const defaultHost = 'localhost'
export const defaultProgramPath = '/untitled/untitled'
export const defaultProjectFolder = '/projects/'

export const sessionStorageKey = 'vectioneer.grid.session'
export const connectionStorageKey = 'vectioneer.grid.connection.'
export const autoSaveStorageKey = 'vectioneer.grid.autosave'

export const gridSaveDelay = 500
export const parameterPointCartPose = [0.5, 0, 0.5, 0, 0, 0]

export const maxUploadChunkSize = 1 * 1024 * 1024
