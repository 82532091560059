import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelColorpicker extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vColorpicker
    this._title = 'colorpicker'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._xCoordinate = {
      title: 'X coordinate',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._yCoordinate = {
      title: 'Y coordinate',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }
  }
}
