import ModelElement from './v-element'
import { programElementTypes, variableTypes as varTypes, libraryGroups } from '/@shared/constants'
import _get from 'lodash.get'
import { namespace } from '/@/shared/utils'
import { empty } from '/@shared/utils';

export default class ModelCreate extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.create
    this._title = 'create'
    this._icon = 'v-create'
    this._canHaveChildren = false
    this._variable = ''
    this._value = ''
    this._variableObjectID = null
    this._valueObjectID = null
    this._programArguments = []
    this._sortOrder = 0
    this._libraryGroup = libraryGroups.SUBPROG
  }

  toJS() {
    if (!this.hasVariable || !this.hasVariable) {
      return ''
    }

    let js = `nop(new LineId("${this.id}"));
    `

    js += this.getProgramJS()

    return js
  }

  getProgramJS() {
    const prefix = this.isNewVariable ? 'var ' : ' '
    const [, programTitle] = this.value.match(/^new (.*)\((.*)\)/)

    let scopedArgumentsString = ''

    if (this.programArguments.length > 0) {
      scopedArgumentsString = this.programArguments
        .filter((a) => !empty(a.title))
        .map((a) => {
          return [varTypes.GEOMETRY, varTypes.ARGUMENT_CONSTRUCTOR].includes(a.type)
            ? `this.${a.title}`
            : a.title
        })
        .join(', ')
    }

    return `${prefix}${this.variable} = new ${namespace(programTitle)}(${scopedArgumentsString});`
  }
}
