import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelDataplot extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vDataPlot
    this._title = 'dataplot'

    this._titleText = {
      sortorder: 2,
      title: 'title',
      group: 'style',
      value: '',
      inputComponentType: inputComponentTypes.input,
    }

    this._datafile = {
      sortorder: 3,
      title: 'data',
      icon: 'file',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._xcol = {
      sortorder: 4,
      title: 'xcol',
      group: 'value',
      value: 0,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._ycol = {
      sortorder: 5,
      title: 'ycol',
      group: 'value',
      value: 1,
      inputComponentType: inputComponentTypes.input,
    }

    this._xlabel = {
      sortorder: 6,
      title: 'x label',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.input,
    }

    this._ylabel = {
      sortorder: 7,
      title: 'y label',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.input,
    }

    this._ymin = {
      sortorder: 8,
      title: 'y min',
      group: 'value',
      value: -1.0,
      inputComponentType: inputComponentTypes.number,
    }

    this._ymax = {
      sortorder: 9,
      title: 'y max',
      group: 'value',
      value: 1.0,
      inputComponentType: inputComponentTypes.number,
    }

    this._plotstyle = {
      sortorder: 10,
      title: 'plotstyle',
      group: 'style',
      value: '{"type":"scatter"}',
      inputComponentType: inputComponentTypes.input,
    }

    this._plotlayout = {
      sortorder: 11,
      title: 'plotlayout',
      group: 'style',
      value: '{"paper_bgcolor":"rgba(255, 255, 255, 255)","plot_bgcolor":"rgba(255, 255, 255, 255)"}',
      inputComponentType: inputComponentTypes.input,
    }

    this._refreshtime = {
      sortorder: 12,
      title: 'refresh every',
      group: 'value',
      value: 10,
      inputComponentType: inputComponentTypes.number,
    }

    this._yautoscale = {
      sortorder: 13,
      title: 'autoscale y',
      group: 'value',
      value: true,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._xrangeslider = {
      sortorder: 14,
      title: 'rangeslider x',
      group: 'value',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._posix = {
      sortorder: 14,
      title: 'Interpret value as POSIX Timestamp',
      group: 'value',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._showlegend = {
      sortorder: 16,
      title: 'show legend',
      group: 'style',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._color = {
      sortorder: 17,
      title: 'color',
      group: 'style',
      value: colorScheme.redDark,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 300,
      inputComponentType: inputComponentTypes.number,
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 300,
      inputComponentType: inputComponentTypes.number,
    }
  }
}
