const componentModels = import.meta.globEager('./**/v-*.js')

class ModelFactory {
  constructor() {
    this.modelMap = new Map()
  }

  create(data) {
    const modelClass = this.getModelClass(data.type)
    const model = new modelClass(data)
    return model
  }

  createBatch(path, excludeList) {
    return Object.keys(componentModels)
      .filter((key) => key.startsWith(path))
      .filter((key) => !excludeList.includes(key.match(/\/(.*)\/(.*).js/)[2]))
      .map((key) => this.fromType(key))
  }

  programElementFromType(type, data = null, parent = null) {
    return this.fromType(`./v-program/v-elements/${type}.js`, data, parent)
  }

  fromType(type, data = null, parent = null) {
    const modelClass = this.getModelClass(type)
    const model = new modelClass(data, parent)
    return model
  }

  getModelClass(type) {
    //TODO:: library components are still prefixed with vgb. Fix this in the future.
    type = type.replace('vgb', 'v')
    type = type.startsWith('./') ? type : './' + type
    type = type.endsWith('.js') ? type : type + '.js'

    if (this.modelMap.has(type)) {
      return this.modelMap.get(type)
    }

    const modelClass = componentModels[type].default
    this.modelMap.set(type, modelClass)
    return modelClass
  }
}

export default new ModelFactory()
