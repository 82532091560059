import _get from 'lodash/get'
import { empty } from '/@/shared/utils'
import { defaultFrequencyDivider } from '/@/shared/config'
import { useCommunication } from '../composables/communication'
export default {
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
  computed: {
    posX() {
      return _get(this.model, 'x.value', 0)
    },
    posY() {
      return _get(this.model, 'y.value', 0)
    },
    zIndex() {
      return _get(this.model, 'z.value', 0)
    },
    filter() {
      return _get(this.model, 'filter', null)
    },
    filterUnit() {
      return _get(this.model, 'filter.settings.unit', null)
    },
    min() {
      return _get(this.model, 'min.value', 0)
    },
    max() {
      return _get(this.model, 'max.value', 0)
    },
    step() {
      return _get(this.model, 'step.value', 0)
    },
    width() {
      return _get(this.model, 'width.value', 100)
    },
    height() {
      return _get(this.model, 'height.value', 100)
    },
    label() {
      return _get(this.model, 'label.value', '')
    },
    color() {
      return _get(this.model, 'color.value', '')
    },
    unit() {
      return _get(this.model, 'unit.value', 0)
    },
    decimals() {
      return _get(this.model, 'decimals.value', 0)
    },
    fontSize() {
      return this.model.fontSize.value
    },
    fontWeight() {
      return _get(this.model, 'fontWeight.value', 400)
    },
    lineHeight() {
      return _get(this.model, 'lineHeight.value', 12)
    },
    align() {
      return _get(this.model, 'align.value', 'right')
    },
    toHex() {
      return _get(this.model, 'toHex.value', false)
    },
    frequencyDivider() {
      return _get(this.model, 'frequencyDivider.value', defaultFrequencyDivider)
    },
    pathPrefix() {
      return _get(this.model, 'path.settings.prefix', '')
    },
    pathSlot() {
      return _get(this.model, 'path.settings.slot', null)
    },
    pathSettings() {
      return _get(this.model, 'path.settings', null)
    },
    pathExpression() {
      return _get(this.model, 'path.settings.expression', null)
    },
    path() {
      return this.pathPrefix + _get(this.model, 'path.value', null)
    },
    visibilityPrefix() {
      return _get(this.model, 'visibility.settings.prefix', '')
    },
    visibilitySlot() {
      return _get(this.model, 'visibility.settings.slot', null)
    },
    visibilityExpression() {
      return _get(this.model, 'visibility.settings.expression', null)
    },
    visibility() {
      return this.visibilityPrefix + _get(this.model, 'visibility.value', null)
    },
    opacity() {
      return this.isDragging ? 1 : 0
    },
    hasFilter() {
      return !empty(this.filter)
    },
    hasFilterUnit() {
      return !empty(this.filterUnit)
    },
    hasUnit() {
      return !empty(this.unit)
    },
    isBackgroundVisible() {
      return this.artboards.models.active.some((m) => m.id === this.model.id)
    },
    isScaleVisible() {
      return this.artboards.models.active.map((m) => m.id).includes(this.model.id)
    },
    backgroundStyleObject() {
      const opacity = this.isBackgroundVisible ? 1 : 0

      return {
        opacity: opacity,
      }
    },
    isAdmin() {
      return this.$route.fullPath.includes('admin')
    },
  },
}
