export default function getHelperMethods(isPairedToGeometry = true) {
  let helperMethods = `
    function clone(value) {
      return JSON.parse(JSON.stringify(value))
    }
  `

  if (isPairedToGeometry) {
    helperMethods += `
    function gridPointGenerate(p1, p2, p3, rows, cols, index) {
      index = index % (rows * cols)

      const vCol = [p2[0] - p1[0], p2[1] - p1[1], p2[2] - p1[2]]
      const vRow = [p3[0] - p1[0], p3[1] - p1[1], p3[2] - p1[2]]

      if (rows === 0 || cols === 0) {
        return p1;
      }

      var nRows = Math.floor(index / cols)
      var nCols = index % cols

      var colFract = 0
      var rowFract = 0

      if (nCols > 0) {
        colFract = nCols / (cols - 1)
      }

      if (nRows > 0) {
        rowFract = nRows / (rows - 1)
      }

      var vTargetCol = [vCol[0] * colFract, vCol[1] * colFract, vCol[2] * colFract]
      var vTargetRow = [vRow[0] * rowFract, vRow[1] * rowFract, vRow[2] * rowFract]

      return [
        p1[0] + vTargetCol[0] + vTargetRow[0],
        p1[1] + vTargetCol[1] + vTargetRow[1],
        p1[2] + vTargetCol[2] + vTargetRow[2],
        p1[3],
        p1[4],
        p1[5],
      ]
    }
    `
  }

  return helperMethods
}
