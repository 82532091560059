import mixinsRaster from '/@mixins/raster'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import { colorScheme } from '/@/shared/constants'

export default {
  data() {
    return {
      colorScheme,
    }
  },
  mixins: [mixinsRaster],
  computed: {
    ...mapStoreGetters(storeTypes.RASTER, ['show', 'snap', 'color', 'dimensions.width', 'dimensions.height']),
  },
}
