import { dotNotationToCamelCase, wrapInArray } from '/@/shared/utils'
import store from '/@vuex/store'

export function mapStoreGetters(storeType, keys) {
  keys = wrapInArray(keys)

  return keys.reduce((carry, key) => {
    const computedKey = dotNotationToCamelCase(key)
    const getterKey = `${storeType}_ATTRIBUTE`
    const mutationKey = `${storeType}_ATTRIBUTE_UPDATE`

    carry[computedKey] = {
      get() {
        return store.getters[getterKey](key)
      },
      set(value) {
        store.commit(mutationKey, {
          key,
          value,
        })
      },
    }

    return carry
  }, {})
}

export const storeTypes = {
  ARTBOARDS: 'ARTBOARDS',
  GRID: 'GRID',
  RASTER: 'RASTER',
  MOTORCORTEX: 'MOTORCORTEX',
  SETTINGS: 'SETTINGS',
  PROGRAMS: 'PROGRAMS',
}
