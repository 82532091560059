import vFlits from '/@components/general/flits/index.vue'
import mixinResource from '/@mixins/resource'
import mixinArtboards from '/@mixins/artboards'
import mixinSettings from '/@mixins/settings'
import { promiseTimeout, empty, handleRouteError } from '/@shared/utils'
import _get from 'lodash.get'
import { types } from '/@vuex/types'
import vuexStore from '/@vuex/store'
import { types as vuexTypes } from '/@vuex/types'
import store from 'store'
import GridImporter from '/@importers/grid'
import { navHeight } from '/@/shared/constants'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'

export default {
  mixins: [mixinResource, mixinArtboards, mixinSettings],
  components: {
    'v-flits': vFlits,
  },
  data() {
    return {
      isLoading: true,
      isPreloaderVisible: false,
      errorMessage: '',
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.SETTINGS, ['application.scale', 'application.fitToScreen']),
    frontStyle() {
      return {
        backgroundColor: this.artboards.colors.background,
      }
    },
    applicationStyle() {
      return {
        transform: `scale(${this.applicationScale})`,
      }
    },
    hasErrorMessage() {
      return !empty(this.errorMessage)
    },
  },
  methods: {
    load() {
      const minloadDuration = DEBUG ? 0 : 1500

      return Promise.all([this.loadAssets(), promiseTimeout(minloadDuration)])
        .then(() => {
          this.redirect()
          this.isLoading = false
        })
        .catch((error) => {
          this.setErrorMessage(error.message || 'Assets could not be loaded.')
          this.redirectToGuiList()
        })
    },
    loadAssets() {
      this.isPreloaderVisible = true

      return new Promise(async (resolve, reject) => {
        try {
          const { data: grid } = await this.downloadResourceFromPath(this.gridPath)
          const importer = new GridImporter(grid)

          if (importer.isOutdatedApp) {
            return this.setErrorMessage("The file you're trying to load is of a newer version. Please update GRID.")
          }

          const data = await importer.import()
          await this.loadErrorFile(data)
          await this.loadStateFile(data)
          await this.commitGridDataToStore(data)
          await this.loadPermissions()
          await this.getControllerApiVersion()

          resolve()
        } catch (error) {
          reject(new Error('Error loading grid file.' + (error.message && ' ' + error.message)))
        }
      })
    },
    redirectToGuiList() {
      store.remove('vectioneer.gui.default')

      setTimeout(() => {
        window.location.replace('/list/')
      }, 3000)
    },
    loadGridFile() {
      return this.downloadResourceFromPath(this.gridPath)
    },
    loadErrorFile(gridData) {
      if (empty(gridData.settings.error.file)) {
        return Promise.resolve()
      }

      return this.downloadResourceFromPath(gridData.settings.error.file)
        .then((response) => {
          gridData.settings.error.codes = response.data
        })
        .catch(() => {
          // no error file is allowed.
        })
    },
    loadStateFile(gridData) {
      if (empty(gridData.settings.state.file)) {
        return Promise.resolve()
      }

      return this.downloadResourceFromPath(gridData.settings.state.file)
        .then((response) => {
          gridData.settings.state.codes = response.data
        })
        .catch(() => {
          // no state file is allowed
        })
    },
    commitGridDataToStore(gridData) {
      return this.$store.dispatch(types.LOAD_GRID, gridData)
    },
    loadPermissions() {
      if (IS_DEPLOYED) {
        this.commitPermissions(['update', 'view'])
        return Promise.resolve()
      }

      return this.$http.get(`projects/${this.projectHash}/permissions`).then((response) => {
        this.commitPermissions(response.data)
      })
    },
    getControllerApiVersion() {
      if (!IS_DEPLOYED) {
        return Promise.resolve()
      }

      return this.$http
        .get(this.apiUrl + 'grid/api/version')
        .then((response) => {
          this.settingsUpdateAttribute('api.version', response.data.version)
        })
        .catch(() => {
          this.settingsUpdateAttribute('api.version', 0)
        })
    },
    commitPermissions(permissions) {
      this.$store.commit(types.SETTINGS_ATTRIBUTE_UPDATE, {
        key: 'permissions',
        value: permissions,
        saveMutation: false,
      })
    },
    redirect() {
      const autoLogin = vuexStore.getters[types.MOTORCORTEX_ATTRIBUTE]('connection.login')
      const routeName = autoLogin ? 'screens' : 'login'

      this.$router.push({ name: routeName }).catch((error) => handleRouteError(error))
    },
    setDocumentTitle() {
      if (IS_DEPLOYED) {
        const grid = decodeURIComponent(this.gridPath).split('/').pop()
        const project = decodeURIComponent(this.projectHash).split('/').shift()

        document.title = `${project} | ${grid}`
      } else {
        this.getResourceFromPath(this.gridPath).then(({ data }) => {
          const projectTitle = data?.data?.directory?.project?.title || 'Motorcortex'
          const gridTitle = data.data.name
          document.title = projectTitle + ' | ' + gridTitle
        })
      }
    },
    setErrorMessage(value) {
      this.errorMessage = value
    },
    addResizeListener(event) {
      window.addEventListener('resize', this.setArtboardScale, true)
      window.screen?.orientation?.addEventListener('change', this.setArtboardScale, true)
    },
    setArtboardScale(event) {
      this.$store.commit(types.SETTINGS_ATTRIBUTE_UPDATE, {
        key: 'application.scale',
        value: this.getScale(),
      })
    },
    getScale() {
      const isFitToScreenEnabled = this.$store.getters[vuexTypes.SETTINGS_ATTRIBUTE]('application.fitToScreen')
      const appHeight = this.artboards.dimensions.height
      const appWidth = this.artboards.dimensions.width
      const windowHeight = window.innerHeight
      const windowWidth = window.innerWidth
      const appAspectRatio = appWidth / appHeight
      const windowAspectRatio = windowWidth / windowHeight

      if (isFitToScreenEnabled) {
        if (windowAspectRatio === appAspectRatio) {
          if (appWidth > windowWidth) {
            return appWidth / windowWidth
          }
        } else if (windowAspectRatio < appAspectRatio) {
          if (appWidth > windowWidth) {
            return windowWidth / appWidth
          }
        } else {
          if (appHeight > windowHeight) {
            return windowHeight / appHeight
          }
        }
      }

      return 1
    },
  },
  mounted() {
    this.load().then(() => {
      this.setDocumentTitle()
      this.addResizeListener()
      this.setArtboardScale()
    })
  },
}
