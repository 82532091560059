import { empty } from '/@shared/utils'

export default class UnsubscribeJob {
  constructor(subscription) {
    this.subscription = subscription
  }

  run() {
    const groupName = this.subscription?.currentSubscriptionData?.groupName

    if (empty(groupName)) {
      return Promise.resolve()
    }

    this.subscription.reset()

    return this.subscription.subscriptionManager.unsubscribe(groupName).catch(() => {
      // fail silently
    })
  }
}
