import mixinsRaster from '/@mixins/raster'
import mixinArtboards from '/@mixins/artboards'

export default {
  mixins: [mixinsRaster, mixinArtboards],
  data: function() {
    return {}
  },
  computed: {
    linesHorizontal() {
      let linesAmount = Math.floor(this.artboards.dimensions.height / this.raster.dimensions.height)

      return [...Array(linesAmount).keys()]
    },
    linesVertical() {
      let linesAmount = Math.floor(this.artboards.dimensions.width / this.raster.dimensions.width)

      return [...Array(linesAmount).keys()]
    },
  },
}
