import axios from 'axios'
import { flits } from '/@plugins/flits'
import _get from 'lodash.get'
import session from '/@shared/session'
import router from '/@shared/router'

export class HttpError extends Error {
  constructor(message) {
    super(message)

    this.name = 'HttpError'
    this.url = ''
    this.payload = ''
    this.status = 0
  }
}

const config = {
  timeout: 0,
  headers: {
    Accept: 'application/json',
  },
}

if (IS_DEPLOYED) {
  const url = new URL(window.location.origin)
  url.pathname = 'services/'

  config.baseURL = url.toString()
} else {
  config.baseURL = API_URL
  config.withCredentials = true
}

const instance = axios.create(config)

instance.interceptors.request.use((config) => {
  config.params = {
    project: session.get('project'),
    ...config.params,
  }

  return config
})

const storePreviousUrl = () => {
  if (router.currentRoute.name !== 'logged-out') {
    session.set('previousUrl', router.currentRoute.fullPath)
  }
}

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const response = error.response

    if (response && response.status) {
      switch (response.status) {
        case 401:
          if (!IS_DEPLOYED) {
            storePreviousUrl()
            return router.push({ name: 'logged-out' })
          }
          break
        case 403:
          flits.error('You do not have permission for this action.')
          flits.error('Changes are not saved.')
          break
      }
    }

    const errorMessage = error.message

    const httpError = new HttpError(errorMessage.trim())
    httpError.url = error.config.url
    httpError.payload = error.config.data
    httpError.stack = error.stack
    httpError.status = response && response.status ? response.status : 0

    return Promise.reject(httpError)
  },
)

export default instance
