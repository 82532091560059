import Model from '/@models/model'

export default class ModelArgument extends Model {
  get isTypePoint() {
    return this.type === argumentTypes.POINT
  }

  get isTypeValue() {
    return this.type === argumentTypes.VALUE
  }

  initVariables() {
    super.initVariables()

    this._title = null
    this._type = argumentTypes.VALUE
    this._default = null
  }

  toJS(isConstructorMethod = false) {
    const titlePrefix = isConstructorMethod ? 'this.' : ''
    let argStr = `${titlePrefix}${this.title} = ${this.title}`

    if (this.default) {
      argStr = `${argStr} || ${this.isTypePoint ? `this.${this.default}` : this.default}`
    }

    argStr += ';'

    return argStr
  }

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      default: this.default,
    }
  }
}

export const argumentTypes = {
  VALUE: 'value',
  POINT: 'point',
}
