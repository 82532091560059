import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'

export default {
  computed: {
    ...mapStoreGetters(storeTypes.SETTINGS, [
      'error.file',
      'error.frequencyDivider',
      'error.definition.folder',
      'error.paths.subscription',
      'error.paths.acknowledge',
    ]),
  },
}
