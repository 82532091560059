import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'

export default class ModelGroup extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.group
    this._title = 'group'
    this._name = ''
    this._icon = 'v-group'
    this._sortOrder = 2
    this._libraryGroup = libraryGroups.FLOW
  }

  toJS(loadedProgramModels) {
    return `
      nop(new LineId("${this.id}"));

      ${this.children.map(c => c.toJS(loadedProgramModels)).join('\n')}`
  }
}
