import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelDropDown extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vDropdown
    this._title = 'dropdown'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
        isSlotRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._map = {
      sortorder: 3,
      group: 'value',
      title: 'map',
      icon: 'file',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._sortBy = {
      sortorder: 4,
      group: 'style',
      title: 'sort by',
      options: [
        {
          title: 'labels',
          value: 'labels',
        },
        {
          title: 'values',
          value: 'values',
        },
      ],
      value: 'labels',
      inputComponentType: inputComponentTypes.radio,
    }

    this._width = {
      group: 'position',
      sortorder: 11,
      title: 'width',
      value: 100,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
