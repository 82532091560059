export const mapVersion = (version: string) => {
  switch (version) {
    case 'v3':
      return ''
    case 'v4':
      return 'v1'
  }

  return version
}
