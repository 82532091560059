import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelJoystick extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vJoystick
    this._title = 'joystick'

    this._pathHorizontal = {
      sortorder: 0,
      title: 'path horizontal',
      value: '',
      settings: {
        slot: null,
        prefix: '',
        expression: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._pathVertical = {
      sortorder: 1,
      title: 'path vertical',
      value: '',
      settings: {
        slot: null,
        prefix: '',
        expression: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._direction = {
      sortorder: 4,
      title: 'direction',
      group: 'style',
      options: [
        {
          title: 'horizontal',
          value: 'horizontal',
        },
        {
          title: 'vertical',
          value: 'vertical',
        },
        {
          title: 'both',
          value: 'both',
        },
      ],
      value: 'both',
      inputComponentType: inputComponentTypes.radio,
    }

    this._updateRate = {
      sortorder: 5,
      title: 'send rate',
      group: 'value',
      value: 20,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 1,
      },
    }

    this._nudgeAmountHorizontal = {
      sortorder: 6,
      title: 'nudge horizontal',
      icon: 'horizontal',
      group: 'value',
      value: 0.05,
      inputComponentType: inputComponentTypes.number,
      settings: {
        decimals: 5,
        step: 0.01,
      },
    }

    this._nudgeAmountVertical = {
      sortorder: 7,
      title: 'nudge vertical',
      icon: 'vertical',
      group: 'value',
      value: 0.05,
      inputComponentType: inputComponentTypes.number,
      settings: {
        decimals: 5,
        step: 0.01,
      },
    }

    this._rangeHorizontal = {
      sortorder: 8,
      title: 'range horizontal',
      icon: 'horizontal',
      group: 'value',
      value: 1,
      inputComponentType: inputComponentTypes.number,
      settings: {},
    }

    this._rangeVertical = {
      sortorder: 9,
      title: 'range vertical',
      icon: 'vertical',
      group: 'value',
      value: 1,
      inputComponentType: inputComponentTypes.number,
      settings: {},
    }

    this._deadZone = {
      sortorder: 10,
      title: 'deadzone',
      group: 'value',
      value: 10,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._radius = {
      sortorder: 11,
      title: 'radius',
      group: 'position',
      value: 100,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 90,
      },
    }
  }
}
