import _throttle from 'lodash/throttle'
import { collisionDetectRectangles } from '/@shared/utils'
import { eventsGlobal } from '/@/constants/events'
import { empty } from '/@shared/utils'

export default {
  data() {
    return {
      isMultiSelectActive: false,
      componentsDownTimer: null,
      selectRectangle: {
        x1: 0,
        x2: 0,
        y1: 0,
        y2: 0,
      },
    }
  },
  computed: {
    selectRectStyle() {
      return {
        transform: `translate(${this.normalizedSelectRectangle.left}px, ${this.normalizedSelectRectangle.top}px)`,
        width: this.normalizedSelectRectangle.width + 'px',
        height: this.normalizedSelectRectangle.height + 'px',
      }
    },
    normalizedSelectRectangle() {
      const top = this.selectRectangle.y1 < this.selectRectangle.y2 ? this.selectRectangle.y1 : this.selectRectangle.y2
      const left = this.selectRectangle.x1 < this.selectRectangle.x2 ? this.selectRectangle.x1 : this.selectRectangle.x2
      const width = Math.abs(this.selectRectangle.x1 - this.selectRectangle.x2)
      const height = Math.abs(this.selectRectangle.y1 - this.selectRectangle.y2)

      return { top, left, width, height }
    },
    isSelectionActive() {
      return this.artboards.models.active.length > 0
    },
  },
  methods: {
    initSelect() {
      this.componentsMouseMoveListerFunction = _throttle(this.artboardMouseMoveLister, 10)
    },
    artboardMouseDownListener(event) {
      if (this.isArtboardClicked(event)) {
        this.deselect()
        this.emitPanelCloseEvent()
        this.activateSelect()
        this.setSelectRectStartPos(event)
      }
    },
    artboardMouseMoveLister(event) {
      this.selectRectangle.x2 = event.clientX - this.comonentsRectangle().left
      this.selectRectangle.y2 = event.clientY - this.comonentsRectangle().top
    },
    windowMouseUpLister() {
      this.selectComponents()
      this.deActivateSelect()
    },
    activateSelect() {
      window.addEventListener('mouseup', this.windowMouseUpLister, false)

      this.componentsDownTimer = setTimeout(() => {
        window.addEventListener('mousemove', this.componentsMouseMoveListerFunction, false)
        this.isMultiSelectActive = true
      }, 200)
    },
    setSelectRectStartPos(event) {
      this.selectRectangle.x1 = this.selectRectangle.x2 = event.clientX - this.comonentsRectangle().left
      this.selectRectangle.y1 = this.selectRectangle.y2 = event.clientY - this.comonentsRectangle().top
    },
    deActivateSelect() {
      clearTimeout(this.componentsDownTimer)
      this.isMultiSelectActive = false

      window.removeEventListener('mousemove', this.componentsMouseMoveListerFunction)
      window.removeEventListener('mouseup', this.windowMouseUpLister)
    },
    selectComponents() {
      const models = this.getSelectedModels()

      if (!empty(models)) {
        this.modelActivate(models)
      }
    },
    getSelectedModels() {
      return this.componentModels.filter((c) => {
        return collisionDetectRectangles(this.componentRectangle(c), this.normalizedSelectRectangle)
      })
    },
    deselect() {
      if (this.isShiftPressed) {
        return
      }

      if (this.isSelectionActive) {
        this.modelActivate()
      }
    },
    emitPanelCloseEvent() {
      this.$bus.$emit(eventsGlobal.TOGGLE_PARAMETER_PANEL, { forceClose: true })
      this.$bus.$emit(eventsGlobal.TOGGLE_LIBRARY_PANEL, { forceClose: true })
    },
    isArtboardClicked(event) {
      return event.target.className.includes('components')
    },
    comonentsRectangle() {
      return this.$refs.components.getBoundingClientRect()
    },
    componentRectangle(component) {
      const componentDomElement = document.getElementById(`component${component.id}`)
      const { top, left, width, height } = componentDomElement.getBoundingClientRect()

      return {
        top: top - this.comonentsRectangle().top,
        left: left - this.comonentsRectangle().left,
        width,
        height,
      }
    },
  },
  mounted() {
    this.initSelect()
  },
}
