import ModelElement from './v-element'
import { programElementTypes } from '/@/shared/constants'

export default class ModelRoot extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.root
    this._title = 'root'
    this._icon = 'program-element-root'
    this._modelToCopy = null
    this._programID = null
    this._methodID = null
    this._geometryID = null
    this._jsGenerationGeometryModel = null
    this._jsGenerationProgramModel = null
  }

  get hasModelToCopy() {
    return this.modelToCopy !== null
  }

  toJS(loadedProgramModels) {
    return `${this.children.map((c) => c.toJS(loadedProgramModels)).join('\n')}`
  }
}
