import mixinMotorcortex from '/@mixins/motorcortex'
import mixinArtboards from '/@mixins/artboards'
import mixinsRaster from '/@mixins/raster'
import { eventsGlobal } from '/@constants/events'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import vConnection from './connection/index.vue'
import vSave from './save/index.vue'
import mixinResource from '/@mixins/resource'

export default {
  components: {
    'v-connection': vConnection,
    'v-save': vSave,
  },
  mixins: [mixinMotorcortex, mixinArtboards, mixinsRaster, mixinResource],
  data() {
    return {
      buildInterval: null,
      isParametersActive: false,
      isLibraryActive: false,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.MOTORCORTEX, 'connection.open'),
  },
  methods: {
    init() {
      this.listenForEvents()
    },
    listenForEvents() {
      this.$bus.$on(eventsGlobal.SELECT_PATH_COMPONENT, this.openParameters)

      this.$bus.$on(eventsGlobal.TOGGLE_LIBRARY_PANEL, (event) => {
        if (event && event.forceClose) {
          this.isLibraryActive = false
        }
      })
      this.$bus.$on(eventsGlobal.TOGGLE_PARAMETER_PANEL, (event) => {
        if (event && event.forceClose) {
          this.isParametersActive = false
        }
      })
    },
    toggleParameters() {
      this.$bus.$emit(eventsGlobal.TOGGLE_PARAMETER_PANEL)
      this.isParametersActive = !this.isParametersActive
      this.isLibraryActive = false
    },
    toggleLibrary() {
      this.$bus.$emit(eventsGlobal.TOGGLE_LIBRARY_PANEL)
      this.isLibraryActive = !this.isLibraryActive
      this.isParametersActive = false
    },
    openParameters() {
      this.isParametersActive = true
      this.isLibraryActive = false
    },
    deploy() {
      return this.getResourceFromPath(this.gridDirname).then((response) => {
        const directory = response.data.data.hash
        const uri = `${DEPLOY_URL}projects/${this.projectHash}/directories/${directory}?deploy=true`
        window.open(uri)
      })
    },
    preview() {
      const url = new URL(window.location.href)
      url.pathname = '/'
      url.searchParams.set('grid', this.gridPath)
      url.searchParams.set('project', this.projectHash)
      window.open(url, '_blank')
    },
  },
  mounted() {
    this.init()
  },
}
