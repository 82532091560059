import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'

export default class ModelComment extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.comment
    this._title = 'comment'
    this._icon = 'v-comment'
    this._canHaveChildren = false
    this._header = '// comment'
    this._body = ''
    this._variableObjectID = null
    this._valueObjectID = null
    this._sortOrder = 1
    this._libraryGroup = libraryGroups.META
  }

  toJS() {
    return ''
  }
}
