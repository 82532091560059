import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import { handleRouteError, empty } from '/@shared/utils'
import mixinArtboards from '/@mixins/artboards'
import mixinMotorcortex from '/@mixins/motorcortex'

export default {
  mixins: [mixinArtboards, mixinMotorcortex],
  data: () => {
    return {
      username: '',
      password: '',
      isAuthenticating: false,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.MOTORCORTEX, ['connection.user.name', 'connection.user.password']),
    host() {
      const host = this.$communication.getConnectionDetails().host
      return empty(host) ? 'localhost' : host
    }
  },
  methods: {
    login() {
      if (empty(this.username)) {
        return this.$flits.error('Enter username.')
      }

      if (empty(this.password)) {
        return this.$flits.error('Enter password.')
      }

      this.isAuthenticating = true

      this.motorcortexUpdateAttribute('connection.user.name', this.username)
      this.motorcortexUpdateAttribute('connection.user.password', this.password)

      this.$communication.init().then(() => {
        this.$router.push({ name: 'screens' }).catch(error => handleRouteError(error))
      }).catch(error => {
        this.$flits.error(error.text)
      })
      .finally(() => {
        this.isAuthenticating = false
      })
    },
  },
}
