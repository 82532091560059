import { empty } from '/@shared/utils'

export default class SubscriptionData {
  constructor(communication, parameters = [], groupName = '', frequencyDivider = 0) {
    this.communication = communication

    this.data = {
      parameters,
      groupName,
      frequencyDivider,
    }
  }

  isValid() {
    return ![this.parameters, this.groupName, this.frequencyDivider, this.getValidParameters()].some((item) => {
      return empty(item)
    })
  }

  isEqualTo(subscriptionData) {
    if (empty(subscriptionData)) {
      return false
    }

    return JSON.stringify(this.data) === JSON.stringify(subscriptionData.data)
  }

  getValidParameters() {
    return this.data.parameters.filter((parameter) => {
      return this.communication.isPathInTree(parameter)
    })
  }

  get parameters() {
    return this.data.parameters
  }

  get groupName() {
    return this.data.groupName
  }

  get frequencyDivider() {
    return this.data.frequencyDivider
  }
}
