import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import collect from 'collect.js'

export default class Model3d extends ModelBase {
  get geometry() {
    return collect(vuexStore.getters[types.ARTBOARDS_STATE].dictionary)
      .values()
      .pluck('components')
      .flatten(1)
      .first((c) => c.id === this.geometryID.value)
  }

  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.v3d
    this._title = '3d'

    this._frequencyDivider = {
      sortorder: 2,
      title: 'frequency divider',
      group: 'value',
      value: 100,
      inputComponentType: inputComponentTypes.number,
      settings: {
        decimals: 0,
        min: 1,
      },
    }

    this._model = {
      sortorder: 2,
      title: 'model',
      icon: 'file',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._disableOrbitControls = {
      sortorder: 3,
      title: 'disable view navigation',
      group: 'value',
      value: false,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 300,
      settings: {
        decimals: 0,
        min: 10,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 300,
      settings: {
        decimals: 0,
        min: 10,
      },
      inputComponentType: inputComponentTypes.number,
    }
  }
}
