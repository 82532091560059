import { accounts } from '/@shared/api'
import session from '/@shared/session'
import { useWindowFocus } from '@vueuse/core'
import { watch } from 'vue'
import router from '/@shared/router'

export default {
  setup() {
    const focused = useWindowFocus()
    const portalUrl = APP_URL

    const redirect = () => {
      accounts
        .me()
        .then(() => {
          router.push(session.get('previousUrl'))
        })
        .catch((error) => {})
    }

    const checkIfLoggedIn = () => {
      if (focused.value) {
        redirect()
      }
    }

    watch(focused, checkIfLoggedIn, { immediate: true })

    return {
      portalUrl,
    }
  },
}
