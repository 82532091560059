import _get from 'lodash.get'
import session from '/@shared/session'
import { maxUploadChunkSize } from '/@shared/config'
import * as tus from 'tus-js-client'

export default {
  computed: {
    projectHash() {
      return session.get('project')
    },
    gridPath() {
      return session.get('grid')
    },
    gridDirname() {
      if (!this.gridPath.includes('/')) {
        return ''
      }

      return this.gridPath.split('/').slice(0, -1).join('/').concat('/')
    },
    apiUrl() {
      if (IS_DEPLOYED) {
        const url = new URL(window.location.origin)
        url.pathname = 'services/'
        return url.toString()
      } else {
        return API_URL
      }
    },
  },
  methods: {
    getResourceFromPath(path) {
      return this.$http.get('fs/frompath', {
        params: {
          path: this.addGridDirnameToPath(path),
        },
      })
    },

    downloadResourceFromPath(path, options = {}) {
      if (IS_DEPLOYED) {
        options.baseURL = ''

        if (!path.startsWith('/')) {
          path = `/projects/${this.projectHash}/${path}`
        }

        return this.$http.get(path, options)
      }

      return this.$http.get(`fs/frompath/download`, {
        ...options,
        params: {
          path: this.addGridDirnameToPath(path),
          project: this.projectHash,
        },
      })
    },

    deleteResourceFromPath(path) {
      if (IS_DEPLOYED) {
        return this.$http.delete('fs/frompath', {
          params: { path },
        })
      }

      return new Promise((resolve, reject) => {
        this.getResourceFromPath(path)
          .then((response) => {
            this.$http
              .delete(`fs/files/${response.data.data.hash}`)
              .then((response) => resolve(response))
              .catch((error) => reject(error))
          })
          .catch((error) => reject(error))
      })
    },

    createResource(path, data) {
      return this.updateResource(path, data)
    },

    updateResource(path, data) {
      return new Promise((resolve, reject) => {
        this.$http
          .post('fs/files/update', {
            project: this.projectHash,
            path: this.addGridDirnameToPath(path),
            data: JSON.stringify(data),
          })
          .then(resolve)
          .catch(() => reject(`Could not create resource at path ${path}`))
      })
    },

    addGridDirnameToPath(path) {
      if (!path) {
        throw new Error('Path is required')
      }

      if (IS_DEPLOYED || path.startsWith(this.gridDirname)) {
        return path
      }

      return this.gridDirname + path
    },

    getResourceDownloadUri(path) {
      if (IS_DEPLOYED) {
        const isPathExternal = path.startsWith('http')
        const isPathAbsolute = path.startsWith('/')

        if (isPathExternal || isPathAbsolute) {
          return path
        }
      }

      const url = new URL('fs/frompath/download', this.apiUrl)
      url.searchParams.append('project', this.projectHash)
      url.searchParams.append('path', this.addGridDirnameToPath(path))

      return url.toString()
    },

    getProjectFromHash(hash) {
      return this.$http.get(`projects/${hash}`)
    },

    searchFromPath(path) {
      return new Promise(async (resolve, reject) => {
        try {
          const response = await this.$http.get('fs/frompath', {
            params: {
              path: this.addGridDirnameToPath(path),
            },
          })
          const info = response.data.data
          info.url = this.addApiKeyToUrl(info.url)
          resolve(info)
        } catch (e) {
          reject(e)
        }
      })
    },

    upload(data, metadata = { filetype: '', filename: '' }, progress = () => {}) {
      const endpoint = IS_DEPLOYED ? this.apiUrl + 'grid/upload/upload/' : APP_URL + 'upload/'

      return new Promise((resolve, reject) => {
        const upload = new tus.Upload(data, {
          endpoint,
          metadata,
          chunkSize: maxUploadChunkSize,
          retryDelays: [0, 1000, 3000, 8000],
          removeFingerprintOnSuccess: true,
          onSuccess: () => {
            resolve(upload)
          },
          onError: (error) => {
            reject(error)
          },
          onProgress: progress,
        })

        upload.start()
      })
    },

    addApiKeyToUrl(url) {
      return `${url}?api_key=${this.aut}`
    },
  },
}
