import Sortable from 'sortablejs'
import mixinArtboards from '/@mixins/artboards'
import { eventsGlobal } from '/@/constants/events'
import { uuid } from '/@shared/utils'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'

export default {
  mixins: [mixinArtboards],
  props: {
    screenWidth: {
      type: String,
    },
  },
  data: function () {
    return {
      isInEditMode: false,
      isMenuVisible: false,
      isMobile: false,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.ARTBOARDS, ['dimensions.width']),
    navigationItems() {
      return this.artboards.order
        .filter((key) => {
          return this.artboards.dictionary.hasOwnProperty(key)
        })
        .map((key) => {
          return {
            title: this.artboards.dictionary[key].title,
            id: this.artboards.dictionary[key].id,
          }
        })
    },
    isMobileAndMenuClosed() {
      return this.isMobile && !this.isMenuVisible
    },
    styleObject() {
      return {
        width: this.screenWidth,
      }
    },
  },
  watch: {
    navigationItems: {
      immediate: true,
      handler() {
        this.$nextTick().then(this.setIsMobile)
      },
    },
  },
  methods: {
    init() {
      this.bindEvents()
      this.initSortable()
      this.setIsMobile()
    },
    bindEvents() {
      window.addEventListener('resize', this.setIsMobile)
    },
    initSortable() {
      this.sortable = Sortable.create(this.$refs.nav, {
        disabled: true,
        animation: 150,
        dataIdAttr: 'data-id',
        onUpdate: () => {
          let order = this.sortable.toArray()
          this.artboardsUpdateAttribute('order', order)
        },
      })
    },
    setIsMobile() {
      const menuWidth = this.$refs.dummyNav.getBoundingClientRect().width
      this.isMobile = Math.min(window.innerWidth, this.dimensionsWidth) - menuWidth < 200
    },
    selectNavItem(id) {
      this.$bus.$emit(eventsGlobal.NAV_CLICKED)
      this.modelActivate()
      this.$router.push({ name: 'artboard', params: { id } }, () => {})
      this.toggleMenu()
    },
    isNavItemActive(item) {
      return this.isArtboardActive && this.artboardActive.id === item.id
    },
    deselectComponents() {
      this.modelActivate()
    },
    async toggleEditMode() {
      if (this.isMobileAndMenuClosed) {
        await this.waitForMenuToBeVisible()
      }

      this.isInEditMode = !this.isInEditMode
      this.sortable.option('disabled', !this.isInEditMode)
    },
    async addArtboard() {
      if (this.isMobileAndMenuClosed) {
        await this.waitForMenuToBeVisible()
      }

      this.artboardsAdd({
        artboardID: uuid(),
      })

      this.redirectToCreatedArtboard()
    },
    waitForMenuToBeVisible() {
      if (this.isMenuVisible) {
        return Promise.resolve()
      }

      this.toggleMenu()
      return new Promise((resolve) => setTimeout(resolve, 500))
    },
    redirectToCreatedArtboard() {
      const artboardIDs = Object.keys(this.artboards.dictionary)
      const artboardID = artboardIDs[artboardIDs.length - 1]
      const isOnLastArtboard = this.$route.name === 'arboard' && this.$route.params.id === artboardID

      if (isOnLastArtboard) {
        return
      }

      this.$router
        .push({
          name: 'artboard',
          params: {
            id: artboardID,
          },
        })
        .catch(() => {})
    },
    toggleMenu() {
      if (this.isMobile && this.isInEditMode && this.isMenuVisible) {
        this.toggleEditMode()
      }

      this.isMenuVisible = !this.isMenuVisible
    },
  },
  mounted() {
    this.init()
  },
}
