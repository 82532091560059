import motorcortex, { SessionState } from '@vectioneer/motorcortex-js'
import store from 'store'
import _get from 'lodash.get'

import { types as vuexTypes } from '/@vuex/types'
import { empty } from '/@shared/utils'
import {
  defaultCommRequestTimeout,
  defaultCommTreeTimeout,
  defaultCommRequestPortSecure,
  defaultCommReceivePortSecure,
} from '/@/shared/config'

import router from '/@shared/router'
import vuexStore from '/@vuex/store'
import { useCommunication } from '../../composables/communication'

class Communication {
  constructor(store, router) {
    this.store = store
    this.router = router

    this.initVariables()
    this.registerSessionManagerObserver()
  }

  get isAdmin() {
    return this.router.currentRoute.fullPath.includes('admin')
  }

  async init() {
    const isConnected = this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.open')
    const connectionDetails = this.getConnectionDetails()

    return new Promise(async (resolve, reject) => {
      if (isConnected) {
        return resolve()
      }

      try {
        this.session = await this.sessionManager.open(
          {
            host: connectionDetails.host,
            request_port: connectionDetails.portRequest,
            subscribe_port: connectionDetails.portReceive,
            security: connectionDetails.secure,
            request_timeout_ms: defaultCommRequestTimeout,
            tree_timeout_ms: defaultCommTreeTimeout,
          },
          {
            login: connectionDetails.username,
            password: connectionDetails.password,
          },
        )

        resolve()
      } catch (e) {
        reject(e)
      }
    })
  }

  getConnectionDetails() {
    const result = IS_DEPLOYED //
      ? this.getDeployConnectionDetails()
      : this.getLocalConnectionDetails()

    return this.setDefaultConnectionDetails(result)
  }

  setDefaultConnectionDetails(details) {
    if (empty(details.host)) {
      details.host = location.hostname
    }

    if (empty(details.portRequest)) {
      details.portRequest = defaultCommRequestPortSecure
    }

    if (empty(details.portReceive)) {
      details.portReceive = defaultCommReceivePortSecure
    }

    return details
  }

  getLocalConnectionDetails() {
    const isAutoLoginEnabled = this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.login')

    const username = this.isAdmin
      ? store.get('vectioneer.grid.connection.username')
      : this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.user.name')

    const password = this.isAdmin
      ? store.get('vectioneer.grid.connection.password')
      : this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.user.password')

    return {
      host: store.get('vectioneer.grid.connection.host'),
      portRequest: store.get('vectioneer.grid.connection.portRequest'),
      portReceive: store.get('vectioneer.grid.connection.portReceive'),
      secure: store.get('vectioneer.grid.connection.secure', true),
      username,
      password,
    }
  }

  getDeployConnectionDetails() {
    return {
      host: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.hosts.general'),
      portRequest: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.ports.request'),
      portReceive: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.ports.receive'),
      secure: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.secure'),
      username: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.user.name'),
      password: this.store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.user.password'),
    }
  }

  delayedInit() {
    const delay = Math.pow(1.5, this.connectionFailCount) * 1000
    setTimeout(this.init.bind(this), delay)
  }

  initVariables() {
    this.messageTypes = new motorcortex.MessageTypes()
    this.requestManager = new motorcortex.Request(this.messageTypes)
    this.subscriptionManager = new motorcortex.SubscribeAsync(this.requestManager)
    this.sessionManager = new motorcortex.SessionManager(this.requestManager, this.subscriptionManager)

    this.isConnected = false
    this.session = null
    this.connectionFailCount = 0
  }

  registerSessionManagerObserver() {
    this.sessionManager.notify(({ state }) => {
      switch (state) {
        case SessionState.CONNECTION_OK:
          this.resetConnectionFailCount()
          this.setConnectionState(true)
          break
        case SessionState.CONNECTION_LOST:
          this.setConnectionState(false)
          break
        case SessionState.CONNECTION_FAILED:
          if (!this.isAdmin && this.isLoginEnabled) {
            return this.redirectToLogin()
          }

          this.increaseConnectionFailCount()
          this.delayedInit()
          break
      }

      if (DEBUG) {
        console.log(`Session: ${SessionState.getInfo(state)}`)
      }
    })
  }

  getModelAttributeSettings(attribute) {
    const slot = _get(attribute, 'settings.slot', null)
    const hasSlot = !empty(slot)
    return !hasSlot ? {} : { offset: slot, length: 1 }
  }

  setModelAttribute(attribute, value) {
    const path = attribute.value
    this.setParameter(path, value, this.getModelAttributeSettings(attribute))
  }

  setModelAttributeList(list) {
    list = list.map((item) => {
      return {
        path: item.attribute.value,
        value: item.value,
        options: this.getModelAttributeSettings(item.attribute),
      }
    })

    this.setParameterList(list)
  }

  setParameter(path, values, settings) {
    if (!this.isConnected) {
      return
    }

    return this.requestManager.setParameter(path, values, this.getParameterSettings(path, settings))
  }

  getParameterSettings(path, settings) {
    let slot = _get(settings, 'slot', null)
    const node = useCommunication().getTreeNodeFor(path)

    if (empty(node)) {
      return {}
    }

    const nodeElements = Number.parseInt(node.info.numberOfElements, 10)

    if (empty(slot) || slot >= nodeElements) {
      return {}
    }

    return {
      offset: slot,
      length: 1,
    }
  }

  setParameterList(list) {
    return this.requestManager.setParameterList(list)
  }

  setConnectionState(value) {
    this.isConnected = value

    this.store.commit(vuexTypes.MOTORCORTEX_ATTRIBUTE_UPDATE, {
      saveMutation: false,
      key: 'connection.open',
      value,
    })
  }

  redirectToLogin() {
    return this.router.push({
      name: 'login',
    })
  }

  getParameterTree() {
    if (!this.isConnected) {
      return []
    }

    return this.session.getTree()
  }

  isPathInTree(path) {
    try {
      const node = this.getParameterTree().getChild(path)
      return !empty(node) && node.children.length === 0
    } catch (e) {
      return false
    }
  }

  resetConnectionFailCount() {
    this.connectionFailCount = 0
  }

  increaseConnectionFailCount() {
    this.connectionFailCount++
  }

  getIsAdmin() {
    return this.router.currentRoute.fullPath.includes('admin')
  }
}

const instance = new Communication(vuexStore, router)

export default {
  install: (Vue, { name = '$communication' }) => {
    Object.defineProperty(Vue.prototype, name, {
      value: instance,
    })
  },
}

export const communication = instance
