import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'
import { empty } from '/@shared/utils'

export default class ModelStopLoop extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.stopLoop
    this._title = 'break'
    this._icon = 'v-break'
    this._requiredAncestors = [programElementTypes.loop]
    this._canHaveChildren = false
    this._sortOrder = 3
    this._libraryGroup = libraryGroups.LOGIC
  }

  toJS() {
    const loopAncestor = this.searchUp(parent => {
      return parent.type === programElementTypes.loop
    })

    return !empty(loopAncestor) //
      ? 'break;'
      : ''
  }
}
