import { types } from '/@vuex/types'
import { mapGetters, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      grid: types.GRID_STATE,
      gridAttribute: types.GRID_ATTRIBUTE,
    }),
  },
  methods: {
    ...mapMutations({
      gridManualSave: types.GRID_MANUAL_SAVE,
    }),
  },
}
