import ModelTree from './v-tree'
import { frameTransform, inverseFrameTransform } from '/@shared/coordinates'
import { matrixFromArray } from '/@shared/matrix'

export default class ModelPoint extends ModelTree {
  get relativeCartesianMatrix() {
    return matrixFromArray(this.cartPose)
  }

  get cartPoseAbsolute() {
    return this.absoluteCartesianMatrix.toArray().flat()
  }

  get absoluteCartesianMatrix() {
    return this.coordinatesCartesianRelativeToAbsolute(this)
  }

  get isWorld() {
    return this.type === 'world'
  }

  get isRobot() {
    return this.type === 'robot'
  }

  get isPoint() {
    return this.type === 'point'
  }

  initVariables() {
    super.initVariables()

    this._type = 'point'
    this._isInEditMode = false
    this._cartPose = [0, 0, 0, 0, 0, 0] // x, y, z, psi, theta, phi
    this._jointPose = [0, 0, 0, 0, 0, 0]
  }

  mapChildren() {
    if (this.canHaveChildren) {
      this.children = this.children.map((child) => {
        return new ModelPoint(child, this)
      })
    }
  }

  addChild(node) {
    if (!this.canNodeBeAdded(node)) {
      return
    }

    let isNodeNew = true
    const parentFrameinGlobal = this.absoluteCartesianMatrix
    const nodeFrameinGlobal = node.absoluteCartesianMatrix

    if (node.parent) {
      isNodeNew = false
      node.parent.removeChild(node)
    }

    node.parent = this
    this.children.push(node)

    if (!isNodeNew) {
      const newNodeFrameInLocal = inverseFrameTransform(parentFrameinGlobal, nodeFrameinGlobal)
      node.cartPose = newNodeFrameInLocal.toArray().flat()
    }
  }

  coordinatesCartesianRelativeToAbsolute(node) {
    if (!node.hasParent) {
      return this.relativeCartesianMatrix
    }

    let parent = node.parent
    let matrix = node.relativeCartesianMatrix

    while (parent && !parent.isRoot) {
      matrix = frameTransform(parent.relativeCartesianMatrix, matrix)
      parent = parent.parent
    }

    return matrix
  }

  coordinatesCartesianAbsoluteToRelative(node) {
    const parentFrameinGlobal = this.coordinatesCartesianRelativeToAbsolute(node.parent)
    const childFrameInLocal = inverseFrameTransform(parentFrameinGlobal, node.absoluteCartesianMatrix)

    return childFrameInLocal
  }

  toJSON() {
    const result = {}
    const properties = ['id', 'title', 'type', 'children', 'cartPose', 'jointPose']

    properties.forEach((key) => result[key] = this[key])
    result.children = this.children.map((child) => child.toJSON())

    return result
  }

  toJS() {
    return `{
      id: "${this.id}",
      title: "${this.title}",
      cartPose: [${this.cartPose}],
      children: [${this.children.map((c) => c.toJS())}],
    }`
  }
}
