import mixinArtboard from '/@mixins/artboards'
import mixinsRaster from '/@mixins/raster'
import mixinMotorcortex from '/@mixins/motorcortex'
import mixinComponent from '/@mixins/component'
import mixinSettings from '/@mixins/settings'
import navigation from './navigation/index.vue'
import throttle from 'lodash/throttle'
import { types as vuexTypes } from '/@vuex/types'
import { empty } from '/@shared/utils'
import { connectionStorageKey } from '/@shared/config'
import store from 'store'
import BaseSubscription from '/@plugins/communication/subscriptions/base'
import SubscriptionData from '/@/plugins/communication/subscriptions/data'
import collect from 'collect.js'
import { useComponentData } from '/@composables/components/data'

export default {
  mixins: [mixinArtboard, mixinsRaster, mixinMotorcortex, mixinSettings, mixinComponent],
  components: {
    'vgb-navigation': navigation,
  },
  data() {
    return {
      isConnectionTimerExpired: false,
      subscriptionMessages: [],
    }
  },
  computed: {
    host() {
      let result = null

      if (IS_DEPLOYED) {
        result = this.$store.getters[vuexTypes.MOTORCORTEX_ATTRIBUTE]('connection.hosts.general')
      } else {
        result = store.get(connectionStorageKey + 'host')
      }

      if (empty(result)) {
        result = location.hostname
      }

      return result
    },
    isConnectionMessageVisible() {
      return this.motorcortex.connection.open === false && this.isConnectionTimerExpired
    },
    styleObject() {
      return {
        height: this.artboards.dimensions.height + 'px',
        width: this.artboards.dimensions.width + 'px',
        backgroundColor: this.artboards.colors.foreground,
      }
    },
    hasArtboards() {
      return !empty(this.artboards.dictionary)
    },
    hasArtboardsVisible() {
      return this.artboardsVisible.length > 0
    },
    artboardsOrdered() {
      return this.artboards.order
        .map((key) => {
          return this.artboards.dictionary[key]
        })
        .filter((artboard) => {
          return !empty(artboard)
        })
    },
    artboardsVisible() {
      return this.artboardsOrdered.filter((artboard) => {
        const hasVisbilityConfiguration = !empty(artboard.visibility.path)

        if (!hasVisbilityConfiguration) {
          return true
        }

        const visibilityValue = this.visibilityParameterForArtboard(artboard)
        const expression = artboard.visibility.expression

        return useComponentData().incorporateExpression(expression, visibilityValue)
      })
    },
    screenVisibilityPaths() {
      return collect(Object.values(this.artboards.dictionary))
        .pluck('visibility.path')
        .filter((path) => !empty(path))
        .unique()
        .toArray()
    },
  },
  watch: {
    artboardsVisible: {
      handler() {
        this.redirectToVisibleArtboard()
      },
      immediate: true,
    },
  },
  methods: {
    init() {
      this.initVariables()
      this.bindEvents()
      this.initCommunication()
      this.initVisibilitySubscription()
      this.redirectToStartScreen()
    },
    initVariables() {
      this.mouseMoveListenerFunction = throttle(this.mouseMoveListener, 25)
      this.mouseMoveStartTimeout = null
      this.scrollable = null
      this.mouseYpositionPrevious = null
    },
    initCommunication() {
      this.$communication.init()
      this.showConnectionMessage()
    },
    initVisibilitySubscription() {
      const subscriptionData = new SubscriptionData(
        this.$communication,
        this.screenVisibilityPaths,
        'grid_screen_visibility',
        100,
      )
      this.subscription = new BaseSubscription(this.$communication, this.subscriptionCallback, subscriptionData)
    },
    subscriptionCallback(messages) {
      this.subscriptionMessages = messages
    },
    visibilityParameterForArtboard(artboard) {
      let result = null
      const index = this.screenVisibilityPaths.indexOf(artboard.visibility.path)

      if (index < this.subscriptionMessages.length) {
        result = this.subscriptionMessages[index].value

        if (Array.isArray(result)) {
          result = result[artboard.visibility.slot]
        }
      }

      return result
    },
    redirectToVisibleArtboard() {
      const isOnVisibleArtboard = collect(this.artboardsVisible).pluck('id').contains(this.$route.params.id)

      if (!isOnVisibleArtboard) {
        const nextVisibleArtboard = this.getNextVisibleArtboard()

        if (nextVisibleArtboard) {
          this.$router.push(
            {
              name: 'screen',
              params: { id: nextVisibleArtboard.id },
            },
            () => {},
          )
        }
      }
    },
    redirectToStartScreen() {
      this.$router.push(
        {
          name: 'screen',
          params: {
            id: this.artboards.startID,
          },
        },
        () => {},
      )
    },
    getNextVisibleArtboard() {
      const currentArtboardID = this.$route.params.id
      const firstArtboard = this.artboardsVisible[0]

      if (!empty(currentArtboardID)) {
        const index = this.artboardsVisible.findIndex((a) => a.id)

        if (index < this.artboardsVisible.length - 2) {
          return this.artboardsVisible[index + 1]
        }
      }

      return firstArtboard
    },
    showConnectionMessage() {
      setTimeout(() => {
        this.isConnectionTimerExpired = true
      }, 1000)
    },
    bindEvents() {
      window.addEventListener('touchstart', this.detectUserTouchesScreen, false)
      window.addEventListener('touchstart', this.mouseDownListener, false)
      window.addEventListener('mousedown', this.mouseDownListener, false)
    },
    unbindEvents() {
      window.removeEventListener('mousemove', this.mouseMoveListenerFunction)
      window.removeEventListener('touchmove', this.mouseMoveListenerFunction)
      window.removeEventListener('mouseup', this.mouseUpListener)
      window.removeEventListener('touchend', this.mouseUpListener)
    },
    detectUserTouchesScreen() {
      window.removeEventListener('touchstart', this.detectUserTouchesScreen)
      this.settingsUpdateAttribute('deviceSupportsTouch', true)
    },
    mouseDownListener(event) {
      if (
        this.settings.deviceSupportsTouchs ||
        event.target.closest('.component') !== null ||
        event.target.closest('.screen') === null
      ) {
        return
      }

      window.addEventListener('mouseup', this.mouseUpListener, false)
      window.addEventListener('touchend', this.mouseUpListener, false)

      this.mouseMoveStartTimeout = setTimeout(() => {
        this.scrollable = event.target.closest('.scrollable')
        this.mouseYpositionPrevious = event.screenY

        window.addEventListener('mousemove', this.mouseMoveListenerFunction, false)
        window.addEventListener('touchmove', this.mouseMoveListenerFunction, false)
      }, 200)
    },
    mouseMoveListener(event) {
      const deltaY = event.screenY - this.mouseYpositionPrevious
      this.mouseYpositionPrevious = event.screenY
      this.scrollable.scrollTop -= deltaY
    },
    mouseUpListener() {
      clearTimeout(this.mouseMoveStartTimeout)
      this.unbindEvents()
    },
  },
  mounted() {
    this.init()
  },
}
