import vRaster from './v-raster/index.vue'
import vComponents from './components/index.vue'
import mixinsRaster from '/@mixins/raster'
import mixinArtboards from '/@mixins/artboards'
import { navHeight } from '/@/shared/constants'

export default {
  mixins: [mixinsRaster, mixinArtboards],
  components: {
    'v-raster': vRaster,
    'v-components': vComponents,
  },
  computed: {
    artboardStyle() {
      const width = this.artboards.dimensions.width
      const height = this.artboards.dimensions.height - navHeight

      return {
        width: `${width}px`,
        height: `${height}px`,
        backgroundColor: this.artboards.colors.foreground,
      }
    },
  },
}
