import ModelBase from './base'
import { inputComponentTypes, colorScheme, componentTypes } from '/@/shared/constants'

export default class ModelDivider extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vDivider
    this._title = 'divider'

    this._horizontal = {
      sortorder: 2,
      title: 'horizontal',
      group: 'value',
      value: true,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._color = {
      sortorder: 3,
      title: 'color',
      group: 'style',
      value: colorScheme.gray6,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._width = {
      sortorder: 1000,
      title: 'size',
      group: 'position',
      value: 300,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 1,
      },
    }
  }
}
