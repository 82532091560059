import methodModel from '/@models/v-program/v-method'
import { defaultMainCreateCommandID, defaultMainCallCommandID } from '/@shared/constants'
import { randomString } from '/@shared/utils';

export default class ModelMainMethod extends methodModel {
  initializeProgram(programTitle) {
    const create = this.elements.all().find((c) => c.id === defaultMainCreateCommandID)
    const call = this.elements.all().find((c) => c.id === defaultMainCallCommandID)

    if (create) {
      create.variable = 'app'
      create.value = `new ${programTitle}()`

      if (call) {
        call.variable = create.variable
        call.value = `run`
      }
    }
  }
}
