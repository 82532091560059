import SubscriptionData from '../data'

export default class SubscribeJob {
  constructor(subscription, data) {
    this.subscription = subscription
    this.data = data
  }

  run() {
    if (!this.data instanceof SubscriptionData || !this.data.isValid()) {
      Promise.reject(new Error('Invalid subscription data.'))
    }

    return new Promise((resolve, reject) => {
      const data = this.data
      const parameters = data.getValidParameters()
      const groupName = data.groupName
      const frequencyDivider = data.frequencyDivider

      try {
        const subscription = this.subscription.subscriptionManager.subscribe(parameters, groupName, frequencyDivider)
        this.subscription.updateSubscriptionData(this.data)
        this.subscription.updateSubscription(subscription)

        resolve(subscription)
      } catch (error) {
        reject(error)
      }
    })
  }
}
