export function matrix(data) {
  return {
    data,
    get(pos) {
      return this.data[pos[0]][pos[1]]
    },
    set(pos, value) {
      this.data[pos[0]][pos[1]] = value
    },
    toArray() {
      return this.data
    },
  }
}

export function multiply(m1, m2) {
  m1 = m1.toArray()
  m2 = m2.toArray()

  if (m1[0].length !== m2.length) {
    return
  }

  var result = []

  for (var i = 0; i < m1.length; i++) {
    result[i] = []

    for (var j = 0; j < m2[0].length; j++) {
      var sum = 0

      for (var k = 0; k < m1[i].length; k++) {
        sum += m1[i][k] * m2[k][j]
      }

      result[i][j] = sum
    }
  }

  return matrix(result)
}

export function transpose(m) {
  m = m.toArray()
  var result = []

  for (var i = 0; i < m[0].length; i++) {
    result[i] = []

    for (var j = 0; j < m.length; j++) {
      result[i][j] = m[j][i]
    }
  }

  return matrix(result)
}

export function matrixFromArray(arr) {
  return matrix(
    arr.map(value => {
      return [value]
    }),
  )
}

export function frameTransform(parentFrameInGlobal, childFrameInLocal) {
  var parentTransform = pose2Homogeneous(parentFrameInGlobal)
  var childTransform = pose2Homogeneous(childFrameInLocal)
  var childInParentTransform = multiply(parentTransform, childTransform)
  return homogeneous2Pose(childInParentTransform)
}

export function inverseFrameTransform(parentFrameInGlobal, childFrameInGlobal) {
  var childInParentTransformInv = pose2HomogeneousInverse(parentFrameInGlobal)
  var parentTransform = pose2Homogeneous(childFrameInGlobal)
  var childTransform = multiply(childInParentTransformInv, parentTransform)
  return homogeneous2Pose(childTransform)
}

export function pose2RotationMatrix(pose) {
  var m = matrix([
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ])

  var sPs = Math.sin(pose.get([3, 0]))
  var cPs = Math.cos(pose.get([3, 0]))
  var sTh = Math.sin(pose.get([4, 0]))
  var cTh = Math.cos(pose.get([4, 0]))
  var sPh = Math.sin(pose.get([5, 0]))
  var cPh = Math.cos(pose.get([5, 0]))

  m.set([0, 0], cTh * cPs)
  m.set([0, 1], sPh * sTh * cPs - cPh * sPs)
  m.set([0, 2], cPh * sTh * cPs + sPh * sPs)
  m.set([1, 0], cTh * sPs)
  m.set([1, 1], sPh * sTh * sPs + cPh * cPs)
  m.set([1, 2], cPh * sTh * sPs - sPh * cPs)
  m.set([2, 0], -sTh)
  m.set([2, 1], sPh * cTh)
  m.set([2, 2], cPh * cTh)

  return m
}

export function pose2Homogeneous(pose) {
  var m = matrix([
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ])
  // position column
  m.set([0, 3], pose.get([0, 0]))
  m.set([1, 3], pose.get([1, 0]))
  m.set([2, 3], pose.get([2, 0]))

  // orientation block matrix
  var rot = pose2RotationMatrix(pose)
  m.set([0, 0], rot.get([0, 0]))
  m.set([0, 1], rot.get([0, 1]))
  m.set([0, 2], rot.get([0, 2]))
  m.set([1, 0], rot.get([1, 0]))
  m.set([1, 1], rot.get([1, 1]))
  m.set([1, 2], rot.get([1, 2]))
  m.set([2, 0], rot.get([2, 0]))
  m.set([2, 1], rot.get([2, 1]))
  m.set([2, 2], rot.get([2, 2]))
  // last row
  m.set([3, 0], 0.0)
  m.set([3, 1], 0.0)
  m.set([3, 2], 0.0)
  m.set([3, 3], 1.0)

  return m
}

export function pose2HomogeneousInverse(pose) {
  var m = matrix([
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ])
  // orientation block matrix
  // Inverse is transpose of original rotation matrix
  var rot = transpose(pose2RotationMatrix(pose))

  m.set([0, 0], rot.get([0, 0]))
  m.set([0, 1], rot.get([0, 1]))
  m.set([0, 2], rot.get([0, 2]))
  m.set([1, 0], rot.get([1, 0]))
  m.set([1, 1], rot.get([1, 1]))
  m.set([1, 2], rot.get([1, 2]))
  m.set([2, 0], rot.get([2, 0]))
  m.set([2, 1], rot.get([2, 1]))
  m.set([2, 2], rot.get([2, 2]))
  // position column
  var trans = matrix([[0], [0], [0]])
  trans.set([0, 0], pose.get([0, 0]))
  trans.set([1, 0], pose.get([1, 0]))
  trans.set([2, 0], pose.get([2, 0]))
  var transInv = multiply(rot, trans)

  m.set([0, 3], -transInv.get([0, 0]))
  m.set([1, 3], -transInv.get([1, 0]))
  m.set([2, 3], -transInv.get([2, 0]))
  // last row
  m.set([3, 0], 0.0)
  m.set([3, 1], 0.0)
  m.set([3, 2], 0.0)
  m.set([3, 3], 1.0)
  return m
}

export function homogeneous2Pose(transform, errorTolerance = 1e-12) {
  var pose = matrix([[0], [0], [0], [0], [0], [0]])

  pose.set([0, 0], transform.get([0, 3]))
  pose.set([1, 0], transform.get([1, 3]))
  pose.set([2, 0], transform.get([2, 3]))

  var epsilon = errorTolerance * errorTolerance

  pose.set(
    [4, 0],
    Math.atan2(
      -transform.get([2, 0]),
      Math.sqrt(transform.get([0, 0]) * transform.get([0, 0]) + transform.get([1, 0]) * transform.get([1, 0])),
    ),
  )
  if (Math.abs(pose.get([4, 0])) > Math.PI / 2.0 - epsilon) {
    pose.set([3, 0], Math.atan2(-transform.get([0, 1]), transform.get([1, 1])))
    pose.set([5, 0], 0.0)
  } else {
    pose.set([5, 0], Math.atan2(transform.get([2, 1]), transform.get([2, 2])))
    pose.set([3, 0], Math.atan2(transform.get([1, 0]), transform.get([0, 0])))
  }

  return pose
}
