import artboard from './artboard/index.vue'
import inspector from './inspector/index.vue'
import topbar from './topbar/index.vue'
import panels from './panels/index.vue'
import navigation from './navigation/index.vue'
import mixinArtboards from '/@mixins/artboards'
import mixinsRaster from '/@mixins/raster'
import mixinMotorcortex from '/@mixins/motorcortex'
import mixinSettings from '/@mixins/settings'
import { empty } from '/@shared/utils'

export default {
  mixins: [mixinArtboards, mixinsRaster, mixinMotorcortex, mixinSettings],
  components: {
    'vgb-artboard': artboard,
    'vgb-inspector': inspector,
    'vgb-topbar': topbar,
    'vgb-panels': panels,
    'vgb-navigation': navigation,
  },
  computed: {
    isRouteParameterValid() {
      const { id } = this.$route.params
      return !empty(id) && this.artboards.dictionary.hasOwnProperty(id)
    },
    artboardHolderStyle() {
      return {
        width: this.artboards.dimensions.width + 'px',
        height: this.artboards.dimensions.height + 'px',
      }
    },
    artboardBackgroundStyle() {
      return {
        background: this.artboards.colors.background,
      }
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        if (!this.isRouteParameterValid) {
          this.redirectToFirstArtboard()
        }
      },
    },
  },
  mounted() {
    this.$communication.init()
  },
}
