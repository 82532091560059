class Flits {
  constructor() {
    this.items = []
  }

  addMultiple(items) {
    if (items === false) {
      return
    }

    if (!Array.isArray(items)) {
      items = [items]
    }

    items.forEach((item, key) => {
      setTimeout(() => {
        this.addSingle(item)
      }, key * 300 + 50)
    })
  }

  addSingle(item, ttl = 8000) {
    if (typeof item === 'string') {
      item = { level: 'error', message: item }
    }

    item.id = this.getUUID()
    this.items.push(item)

    setTimeout(() => {
      this.remove(item)
    }, ttl)

    return item
  }

  getUUID() {
    /* tslint:disable */
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
    /* tslint:enable */
  }

  remove(item) {
    const index = this.items.findIndex(i => {
      return i.id === item.id
    })

    this.items.splice(index, 1)
  }

  onClick(item) {
    this.remove(item)
  }

  success(message, link) {
    this.addSingle({
      level: 'success',
      message,
      link,
    })
  }

  error(message, link) {
    this.addSingle({
      level: 'error',
      message,
      link,
    })
  }

  info(message, link = null) {
    this.addSingle({
      level: 'info',
      message,
      link,
    })
  }
}

const flits = new Flits()

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$flits', { value: flits })

    Vue.mixin({
      data: () => {
        return {
          flits: flits.items,
        }
      },
    })
  },
}

export { flits }
