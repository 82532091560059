import ModelBase from './base'
import {
  componentTypes,
  inputComponentTypes,
  subscriptionScopes,
  defaultProgramName,
  variableTypes,
  programElementTypes,
  defaultMainCreateCommandID,
  defaultMainCallCommandID,
  argumentTypes,
} from '/@shared/constants'
import _get from 'lodash.get'
import methodModel from './v-program/v-method'
import { empty, trim, uuid, namespace } from '/@shared/utils'
import getHelperMethods from '/@shared/interpreter'
import ModelGeometry from './v-geometry'
import ModelMainMethod from '/@models/v-program/v-main-method'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import { mapVersion } from './v-program/formatters'

export default class ModelProgram extends ModelBase {
  static forge(programData, geometryData) {
    const programModel = new ModelProgram(programData)

    if (!empty(geometryData)) {
      const geometryModel = new ModelGeometry(geometryData)
      geometryModel.rootPoint.jsGenerationGeometryModel = geometryModel
      geometryModel.rootPoint.jsGenerationProgramModel = programModel

      programModel.allMethods.forEach(method => {
        method.jsGenerationGeometryModel = geometryModel
        method.elements.jsGenerationGeometryModel = geometryModel

        method.jsGenerationProgramModel = programModel
        method.elements.jsGenerationProgramModel = programModel
      });

      programModel.jsGenerationGeometryModel = geometryModel
    }

    return programModel
  }

  get elements() {
    let elements = []

    if (this.main) {
      elements = this.methods.reduce((c, m) => {
        return c.concat(m.elements.all())
      }, [])
    }

    if (this.isAdvancedModeActive && this.main) {
      elements = elements.concat(this.main.elements.all())
    }

    return elements
  }

  get arguments() {
    return this.methods[0].arguments
  }

  get allMethods() {
    return this.methods.concat([this.main])
  }

  get baseProgramTitle() {
    return this.programTitle.replace('.prog', '')
  }

  get constructorMethod() {
    return this.methods[0]
  }

  get variables() {
    return this.elements
      .filter((e) => e.isSet)
      .filter((e) => e.variable.startsWith('this.'))
      .map((e) => {
        return {
          type: variableTypes.SET_GLOBAL,
          id: e.id,
          title: e.variable,
        }
      })
  }

  get constructorArguments() {
    return this.constructorMethod.arguments
      .filter((a) => !empty(a.title))
      .map((a) => {
        return {
          type: variableTypes.ARGUMENT_CONSTRUCTOR,
          id: a.id,
          title: a.title,
          isPoint: a.type === argumentTypes.POINT,
        }
      })
  }

  get activeMethod() {
    const m = this.methods.concat([this.main]).find((m) => m.isActive)
    return m ? m : this.methods[0]
  }

  get activeModels() {
    return vuexStore.getters[types.ARTBOARD_ACTIVE_MODELS]
  }

  get geometryModel() {
    // when deploying a program, subprograms need geometry not available in the store.
    if (this.jsGenerationGeometryModel) {
      return this.jsGenerationGeometryModel
    }

    return this.activeModels[this.geometryID.value]
  }

  get isPairedToGeometry() {
    return !empty(this.geometryModel)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vProgram
    this._title = 'program'
    this._methods = []
    this._programTitle = defaultProgramName
    this._currentCommandId = null
    this._isAdvancedModeActive = false
    this._mainMethod = {
      shouldMerge: false,
      id: 'a53adf05-5080-4467-9ce1-c9244131833e',
      title: 'main',
      argumentList: [],
      isActive: false,
      elementTree: {
        id: uuid(),
        type: programElementTypes.root,
        isActive: true,
        children: [
          {
            id: defaultMainCreateCommandID,
            type: programElementTypes.create,
            variable: '',
            value: '',
          },
          {
            id: defaultMainCallCommandID,
            type: programElementTypes.call,
            variable: '',
            value: '',
          },
        ],
      },
      isDeletable: false,
      isCollapsable: false,
      isMenuVisible: false,
    }
    this._methodList = [
      {
        id: 'b53acf05-5080-4467-9ce1-c9244131824c',
        title: 'constructor',
        argumentList: [],
        elementTree: {},
        isDeletable: false,
        isActive: false,
      },
      {
        id: 'c53acf05-5080-4467-9ce1-c9244131824d',
        title: 'run',
        argumentList: [],
        elementTree: {},
        isDeletable: false,
        isActive: true,
      },
    ]

    this._geometryID = {
      sortorder: 4,
      title: 'pair geometry',
      icon: 'vGeometry',
      value: '',
      settings: {
        labels: {
          default: 'Select a geometry component to pair.',
          pairing: 'Click on the desired geometry panel.',
          paired: 'Geometry component paired.',
        },
        validType: componentTypes.vGeometry,
        modelAttribute: 'geometryID',
      },
      inputComponentType: inputComponentTypes.pair,
    }

    this._jsGenerationGeometryModel = null

    this._collisionPath = {
      sortorder: 4,
      title: 'collision detector path',
      value: 'root/Control/collisionDetectorsEnabled',
      group: 'value',
      subscriptionScope: subscriptionScopes.COMPONENT,
    }

    this._currentProgramIdPath = {
      sortorder: 5,
      title: 'current program ID path',
      value: 'root/MotionJSInterpreter/currentProgramId',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.COMPONENT,
    }

    this._currentCommandIdPath = {
      sortorder: 6,
      title: 'path to current line ID',
      value: 'root/MotionJSInterpreter/lineId',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.COMPONENT,
    }

    this._modePath = {
      sortorder: 7,
      title: 'mode path',
      value: 'root/Logic/mode',
      group: 'value',
      subscriptionScope: subscriptionScopes.COMPONENT,
    }

    this._storagePath = {
      sortorder: 8,
      title: 'storage folder',
      value: '/programs',
      group: 'value',
      inputComponentType: inputComponentTypes.input,
    }

    this._mode = {
      sortorder: 15,
      title: 'mode on the controller, updated from program component',
      group: 'value',
      value: 0,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 300,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 300
      }
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 300,
      inputComponentType: inputComponentTypes.number,
    }

    this._systemId = {
      sortorder: 1002,
      title: 'system ID',
      group: 'value',
      value: null,
      inputComponentType: inputComponentTypes.number,
    }

    this._motionJsVersion = {
      sortorder: 1003,
      title: 'Message format',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.select,
      options: [
        {
          label: 'Robot v1-v3',
          key: ''
        },
        {
          label: 'Robot v4+',
          key: 'v1'
        }],
      settings: {
        // TODO: Move this into an importer when migrating to grid: v6
        valueFormatters: () => [mapVersion],
      },
    }

    this._main = {}
    this._methods = []
  }

  boot() {
    const componentIDs = { programID: this.id, geometryID: this.geometryID.value }

    this.methods = this.methodList.map((m) => {
      return new methodModel({
        ...m,
        ...componentIDs
      })
    })

    this.main = new ModelMainMethod({
      ...this.mainMethod,
      ...componentIDs
    })

    this.main.initializeProgram(this.baseProgramTitle)
  }

  addMethod(title) {
    this.methods.push(
      new methodModel({
        programElement: this,
        isActive: false,
        title,
        ... { programID: this.id, geometryID: this.geometryID.value }
      }),
    )
  }

  updateMainMethod(model) {
    this.main = model
  }

  updateMethod(model) {
    const index = this.methods.findIndex((m) => m.id === model.id)
    this.methods.splice(index, 1, model)
  }

  hasVariable(callback) {
    return this.variables.some(callback)
  }

  findVariable(callback) {
    return this.variables.find(callback)
  }

  getPath() {
    const storagePath = trim(this.storagePath.value, '/')
    const programTitle = trim(this.programTitle, '/')
    return `${storagePath}/${programTitle}.prog`
  }

  getClassJS(loadedProgramModels) {
    let js = this.constructorMethod.toJS(this.baseProgramTitle, loadedProgramModels)

    js = this.methods
      .filter((m) => m.title !== 'constructor')
      .reduce((c, m) => {
        return c + `${namespace(this.baseProgramTitle)}.prototype.${m.title} = ${m.toJS(null, loadedProgramModels)}`
      }, js)

    return js
  }

  toJS(loadedProgramModels) {
    let js = ''

    const allProgramModels = loadedProgramModels.concat([this])

    js += allProgramModels.reduce((carry, program) => {
      return carry + program.getClassJS(allProgramModels)
    }, '')

    js += getHelperMethods(true) + '\n'

    js += this.main.elements.toJS(allProgramModels) + '\n'

    return js
  }

  toStorageJSON() {
    return {
      program: {
        id: this.id,
        isAdvancedModeActive: this.isAdvancedModeActive,
        methodList: this.methods.map((m) => m.toJSON()),
        mainMethod: this.main.toJSON(),
      },
      geometry: {
        points: this.isPairedToGeometry ? this.geometryModel.rootPoint.toJSON() : new ModelGeometry().rootPoint.toJSON(),
      },
      version: vuexStore.getters[types.SETTINGS_STATE].progVersion,
    }
  }
}
