import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelMiniplot extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vMiniPlot
    this._title = 'miniplot'

    this._frequencyDivider = {
      group: 'value',
      sortorder: 2,
      title: 'frequency divider',
      value: 100,
      inputComponentType: inputComponentTypes.number,
    }

    this._timerange = {
      group: 'value',
      sortorder: 3,
      title: 'time range',
      value: 1.0,
      inputComponentType: inputComponentTypes.number,
    }

    this._ymin = {
      group: 'value',
      sortorder: 4,
      title: 'y min',
      value: -1.0,
      inputComponentType: inputComponentTypes.number,
    }

    this._ymax = {
      group: 'value',
      sortorder: 5,
      title: 'y max',
      value: 1.0,
      inputComponentType: inputComponentTypes.number,
    }

    this._path0 = {
      group: 'value',
      sortorder: 6,
      title: 'path 1',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.blueLight,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._isFirstPathXaxis = {
      group: 'value',
      sortorder: 7,
      title: 'use as x axis',
      value: null,
      settings: { isActive: false, min: 0, max: 1 },
      inputComponentType: inputComponentTypes.range,
    }

    this._path1 = {
      group: 'value',
      sortorder: 8,
      title: 'path 2',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.redDark,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._path2 = {
      group: 'value',
      sortorder: 9,
      title: 'path 3',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.yellow,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._path3 = {
      group: 'value',
      sortorder: 10,
      title: 'path 4',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.turqoise,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._path4 = {
      group: 'value',
      sortorder: 11,
      title: 'path 5',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.orange,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._path5 = {
      group: 'value',
      sortorder: 12,
      title: 'path 6',
      value: '',
      settings: {
        slot: null,
        expression: '',
        color: colorScheme.green,
        prefix: '',
      },
      inputComponentType: inputComponentTypes.pathColor,
    }

    this._width = {
      group: 'position',
      sortorder: 1000,
      title: 'width',
      value: 300,
      inputComponentType: inputComponentTypes.number,
    }

    this._height = {
      group: 'position',
      sortorder: 1001,
      title: 'height',
      value: 300,
      inputComponentType: inputComponentTypes.number,
    }
  }
}
