import { isValidJson, empty } from '/@shared/utils'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import { keys } from '/@shared/constants'
import factory from '/@models/factory'

export default {
  data() {
    return {
      isControlPressed: false,
      isShiftPressed: false,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.GRID, 'version'),
  },
  methods: {
    initKeyPress() {
      document.addEventListener('keydown', this.keyDownListener)
      document.addEventListener('keyup', this.keyUpListener)
    },
    keyDownListener({ key }) {
      switch (key) {
        case keys.BACKSPACE:
        case keys.DELETE:
          this.deleteComponents()
          break
        case keys.CONTROL:
        case keys.COMMAND:
          this.isControlPressed = true
          break
        case keys.SHIFT:
          this.isShiftPressed = true
          break
        case keys.C:
          this.copyComponents()
          break
        case keys.V:
          this.pasteComponents()
          break
        case keys.Z:
          this.undoRedo()
          break
      }
    },
    keyUpListener({ key }) {
      this.isShiftPressed = false

      if ([keys.CONTROL, keys.COMMAND].includes(key)) {
        this.isControlPressed = false
      }
    },
    undoRedo() {
      if (this.isControlPressed && this.isShiftPressed) {
        this.redo()
      } else if (this.isControlPressed) {
        this.undo()
      }
    },
    copyComponents() {
      if (!this.canCopyPaste()) {
        return
      }

      window.navigator.clipboard.writeText(
        JSON.stringify({
          models: this.activeModels.map((c) => c.toJSON()),
          version: this.version,
        }),
      )
    },
    pasteComponents() {
      if (!this.canCopyPaste()) {
        return
      }

      window.navigator.clipboard.readText().then((data) => {
        if (!isValidJson(data)) {
          return
        }

        data = JSON.parse(data)
        const version = data.version
        const models = data.models.map((m) => factory.create(m))
        const isVersionCurrentVersion = Number.parseInt(version) === Number.parseInt(this.version)
        const artboard = this.artboardActive

        if (empty(models) || !isVersionCurrentVersion) {
          return
        }

        this.artboardsComponentsPaste({ artboard, models }).then((data) => {
          this.modelActivate(data.models)
        })
      })
    },
    deleteComponents() {
      if (this.isDomInputElementActive()) {
        return
      }

      this.modelDelete({
        model: this.activeModels,
        artboard: this.artboardActive,
      })

      this.deselect()
    },
    canCopyPaste() {
      if (!window.navigator.clipboard) {
        return
      }

      return this.isControlPressed && !this.isDomInputElementActive()
    },
    isDomInputElementActive() {
      return ['input', 'textarea'].includes(this.activeDomElement())
    },
    activeDomElement() {
      return (document.activeElement.tagName || '').toLowerCase()
    },
  },
  mounted() {
    this.initKeyPress()
  },
}
