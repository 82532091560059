import vFlits from '/@components/general/flits/index.vue'
import vParserModal from './parser-modal/index.vue'
import mixinResource from '/@mixins/resource'
import { empty } from '/@shared/utils'
import _get from 'lodash.get'
import { types } from '/@vuex/types'
import VueRouter from 'vue-router'
import GridImporter from '/@importers/grid'
import vuexStore from '/@vuex/store'

export default {
  mixins: [mixinResource],
  components: {
    'v-flits': vFlits,
    'vgb-parser-modal': vParserModal,
  },
  data() {
    return {
      isRouterViewEnabled: false,
      isPreloaderVisible: true,
      parserResolve: null,
      errorMessage: '',
    }
  },
  computed: {
    hasErrorMessage() {
      return !empty(this.errorMessage)
    },
    currentGridVersion() {
      return vuexStore.getters[types.SETTINGS_STATE].gridVersion
    },
  },
  methods: {
    async load() {
      const { data: grid } = await this.downloadResourceFromPath(this.gridPath)
      const importer = new GridImporter(grid)
      const isOutdatedVersion = importer.isOutdatedVersion

      if (importer.isOutdatedApp) {
        return this.setErrorMessage("The file you're trying to load is of a newer version. Please update GRID.")
      }

      if (isOutdatedVersion) {
        await this.promptOverwriteFile()
      }

      try {
        const data = await importer.import()
        await this.loadErrorFile(data)
        await this.loadStateFile(data)
        await this.commitGridDataToStore(data)
        await this.loadPermissions()

        if (isOutdatedVersion) {
          await this.saveParsedGridData()
        }
      } catch (e) {
        this.setErrorMessage('Grid assets could not be loaded.')
        throw e
      }

      this.enableRouterView()
      this.preventJsonPasteToInputs()
      this.setDocumentTitle()
      this.redirect()
    },
    promptOverwriteFile() {
      return new Promise((resolve) => {
        this.$dialog.open('parserMessageDialog')
        this.parserResolve = resolve
      })
    },
    loadErrorFile(gridData) {
      if (empty(gridData.settings.error.file)) {
        return Promise.resolve()
      }

      return this.downloadResourceFromPath(gridData.settings.error.file)
        .then((response) => {
          gridData.settings.error.codes = response.data
        })
        .catch(() => {
          // no error file is allowed.
        })
    },
    loadStateFile(gridData) {
      if (empty(gridData.settings.state.file)) {
        return Promise.resolve()
      }

      return this.downloadResourceFromPath(gridData.settings.state.file)
        .then((response) => {
          gridData.settings.state.codes = response.data
        })
        .catch(() => {
          // no state file is allowed.
        })
    },
    commitGridDataToStore(gridData) {
      return this.$store.dispatch(types.LOAD_GRID, gridData)
    },
    saveParsedGridData() {
      return this.$store.commit(types.GRID_MANUAL_SAVE)
    },
    loadPermissions() {
      if (IS_DEPLOYED) {
        this.commitPermissions(['update', 'view'])
        return Promise.resolve()
      }

      return this.$http.get(`projects/${this.projectHash}/permissions`).then((response) => {
        this.commitPermissions(response.data)
      })
    },
    commitPermissions(permissions) {
      this.$store.commit(types.SETTINGS_ATTRIBUTE_UPDATE, {
        key: 'permissions',
        value: permissions,
        saveMutation: false,
      })
    },
    preventJsonPasteToInputs() {
      this.$el.addEventListener('paste', (event) => {
        if (['input', 'textarea'].includes(event.target.nodeName.toLowerCase())) {
          const data = (event.clipboardData || window.clipboardData).getData('text')

          if (this.isPasteDataComponentData(data)) {
            event.preventDefault()
          }
        }
      })
    },
    isPasteDataComponentData(data) {
      if (empty(data)) {
        return false
      }

      try {
        const jsonData = JSON.parse(data)

        if (jsonData && jsonData.hasOwnProperty('models')) {
          return true
        }

        return false
      } catch (e) {
        return false
      }
    },
    redirect() {
      if (this.$route.name !== 'admin') {
        return
      }

      this.$router
        .push({
          name: 'artboards',
        })
        .catch((error) => {
          const { isNavigationFailure, NavigationFailureType } = VueRouter
          const isRedirectError = isNavigationFailure(error, NavigationFailureType.redirected)

          if (!isRedirectError) {
            throw error
          }
        })
    },
    setDocumentTitle() {
      if (IS_DEPLOYED) {
        return
      }

      this.getResourceFromPath(this.gridPath).then(({ data }) => {
        const projectTitle = data?.data?.directory?.project?.title || 'Motorcortex'
        const gridTitle = data.data.name

        document.title = 'GRID | ' + projectTitle + ' | ' + gridTitle
      })
    },
    enableRouterView() {
      this.isRouterViewEnabled = true
    },
    setErrorMessage(value) {
      this.errorMessage = value
    },
  },
  mounted() {
    this.load()
  },
}
