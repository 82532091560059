import authenticate from '/@views/authenticate/index.vue'
import admin from '/@views/admin/index.vue'
import front from '/@views/front/index.vue'
import artboards from '/@views/admin/artboards/index.vue'
import artboard from '/@views/admin/artboards/artboard/index.vue'
import loggedOut from '/@views/logged-out/index.vue'
import screens from '/@views/front/screens/index.vue'
import screen from '/@views/front/screens/screen/index.vue'
import login from '/@views/front/login/index.vue'

export default [
  {
    path: '/authenticate',
    name: 'authenticate',
    component: authenticate,
  },
  {
    path: '/logged-out',
    name: 'logged-out',
    component: loggedOut,
  },
  {
    path: '/admin',
    name: 'admin',
    component: admin,
    children: [
      {
        path: 'artboards',
        name: 'artboards',
        component: artboards,
        children: [
          {
            path: ':id',
            name: 'artboard',
            component: artboard,
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'front',
    component: front,
    children: [
      {
        path: 'login',
        name: 'login',
        component: login,
        props: true,
      },
      {
        path: 'screens',
        name: 'screens',
        component: screens,
        children: [
          {
            path: ':id?',
            name: 'screen',
            component: screen,
          },
        ],
      },
    ],
  },
]
