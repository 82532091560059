import { uuid, isObject } from '/@/shared/utils'
import _cloneDeep from 'lodash.clonedeep'
import _get from 'lodash.get'
import _merge from 'lodash.merge'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import ModelBase from './model'
import Vue from 'vue'
import { modelLifeCycles } from '/@shared/constants'

export default class Model {
  constructor(data) {
    this.initVariables()
    this.setData(data)
    this.createProxies()
    this.generateGettersAndSetters()
    this.mapChildren()
    this.boot()

    this._lifeCycle = modelLifeCycles.BOOTED
  }

  get key() {
    return this.id.substr(0, 8)
  }

  get isBooting() {
    return this._lifeCycle !== modelLifeCycles.BOOTED
  }

  initVariables() {
    this._id = uuid()
    this._lifeCycle = modelLifeCycles.CREATED
  }

  mapChildren() {}

  setData(data) {
    for (let key in data) {
      const value = data[key]
      const internalKey = '_' + key

      if (this.hasOwnProperty(internalKey)) {
        if (value instanceof ModelBase) {
          this[internalKey] = value
        } else if (isObject(value)) {
          this[internalKey] = this.mergeAttribute(internalKey, value)
        } else {
          this[internalKey] = value
        }
      }
    }
  }

  mergeAttribute(internalKey, value) {
    let result = null
    const inputData = value
    const configData = this[internalKey]

    if (isObject(configData)) {
      const shouldMerge = _get(configData, 'shouldMerge', true)

      if (shouldMerge) {
        result = _merge(configData, inputData)
      } else {
        result = inputData
      }

      //TODO, remove this when upgrading performance, remove settings alltogether.
      if (configData.hasOwnProperty('settings')) {
        for (let settingKey in configData.settings) {
          if (!result.settings.hasOwnProperty(settingKey)) {
            result.settings[settingKey] = configData.settings[settingKey]
          }
        }
      }
    }

    return result
  }

  createProxies() {
    Object.keys(this).forEach((internalKey) => {
      if (Array.isArray(this[internalKey])) {
        this[internalKey] = this.getProxy(internalKey, this[internalKey])
      }
    })
  }

  getProxy(internalKey, original) {
    const handler = {
      get: (target, prop, receiver) => {
        const action = target[prop]
        if (typeof action === 'function' && ['push', 'shift', 'pop', 'splice'].includes(prop)) {
          return (...args) => {
            const copy = [...target]
            Array.prototype[prop].apply(copy, args)
            vuexStore.commit(types.MODEL_UPDATE, { model: this, attribute: internalKey, value: copy })
          }
        }

        return Reflect.get(target, prop, receiver)
      },
    }

    return new Proxy(original, handler)
  }

  generateGettersAndSetters() {
    Object.keys(this).forEach((internalKey) => {
      const publicKey = internalKey.slice(1)

      Object.defineProperty(this, publicKey, {
        get: function () {
          return this[internalKey]
        },
        set: function (value) {
          vuexStore.commit(types.MODEL_UPDATE, {
            model: this,
            attribute: internalKey,
            value,
          })
        },
      })
    })
  }

  setInternalAttribute(attribute, value) {
    if (Array.isArray(this[attribute])) {
      Vue.set(this, attribute, this.getProxy(attribute, value))
    } else if (this.isModelInputAttribute(this[attribute])) {
      if (!isObject(value)) {
        value = { value }
      }

      Vue.set(this, attribute, _merge(this[attribute], value))
    } else {
      Vue.set(this, attribute, value)
    }
  }

  isModelInputAttribute(attr) {
    return attr && attr.hasOwnProperty('inputComponentType')
  }

  getProgramModel() {
    return vuexStore.getters[types.ARTBOARDS_MODEL_PROGRAM]
  }

  getGeometryModel() {
    return vuexStore.getters[types.ARTBOARDS_MODEL_GEOMETRY]
  }

  is(v) {
    return this.id === v.id
  }

  boot() {}

  toJSON() {}
}
