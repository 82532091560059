import mixinDrag from './mixins/drag'
import mixinKeyPress from './mixins/keypress'
import mixinArtboards from '/@mixins/artboards'
import mixinsRaster from '/@mixins/raster'
import BaseSubscription from '/@plugins/communication/subscriptions/base'
import SubscriptionData from '/@plugins/communication/subscriptions/data'
import { defaultFrequencyDivider } from '/@/shared/config'
import { slugify, arraysContainSameValues } from '/@shared/utils'
import _get from 'lodash.get'
import collect from 'collect.js'
import { mapStoreGetters, storeTypes } from '/@/vuex/helpers'
import mixinSelect from './mixins/select'
import { useComponentData } from '/@composables/components/data'

export default {
  mixins: [mixinArtboards, mixinDrag, mixinSelect, mixinsRaster, mixinKeyPress],
  data: () => {
    return {
      isSubscribed: false,
    }
  },
  computed: {
    ...mapStoreGetters(storeTypes.MOTORCORTEX, 'connection.open'),
    isAdmin() {
      return this.$route.fullPath.includes('admin')
    },
    componentPaths() {
      return collect(this.componentModels)
        .reduce((carry, model) => carry.concat(model.getPathValues()), collect())
        .unique()
        .toArray()
    },
    groupName() {
      return _get(this.artboardActive, 'groupName', slugify(_get(this.artboardActive, 'title')))
    },
    frequencyDivider() {
      return Number.parseInt(this.artboardActive.frequencyDivider || defaultFrequencyDivider)
    },
  },
  watch: {
    componentPaths: {
      immediate: true,
      handler() {
        this.subscribe()
      },
    },
    connectionOpen: {
      immediate: true,
      handler() {
        this.subscribe()
      },
    },
    artboardActive: {
      handler() {
        useComponentData().startUpdate(this.frequencyDivider)
        this.isSubscribed = true
      },
      immediate: true,
    },
  },
  methods: {
    getComponentStyle(component) {
      return {
        zIndex: component.z.value,
        transform: `translate3d(${component.x.value}px, ${component.y.value}px, 0)`,
      }
    },
    isComponentActive(model) {
      return this.artboards.models.active.map((m) => m.id).includes(model.id)
    },
    initSubscription() {
      const composable = useComponentData()

      this.subscription = new BaseSubscription(this.$communication)
      this.subscription.on('error', (error) => {
        composable.stopUpdate()
        this.$flits.error('Error subscribing to widgets.')
        throw error
      })

      composable.init(this.subscription)
    },
    subscribe() {
      if (!this.subscription) {
        this.initSubscription()
      }

      if (!this.connectionOpen) {
        return false
      }

      const subscriptionData = new SubscriptionData(
        this.$communication,
        this.componentPaths,
        this.groupName,
        this.frequencyDivider,
      )

      this.subscription.subscribe(subscriptionData)
    },
    getComponentStyle(component) {
      return {
        zIndex: component.z.value,
        transform: `translate3d(${component.x.value}px, ${component.y.value}px, 0)`,
      }
    },
  },
  beforeDestroy() {
    useComponentData().stopUpdate()
    this.subscription.destroy()
  },
}
