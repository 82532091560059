import ModelBase from './base'
import { inputComponentTypes, componentTypes } from '/@/shared/constants'

export default class ModelImage extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vImage
    this._title = 'image'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 2,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
        isRequired: false,
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._fileName = {
      sortorder: 3,
      title: 'file',
      icon: 'file',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._indexFile = {
      sortorder: 4,
      title: 'map',
      icon: 'file',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.resource,
    }

    this._opacity = {
      sortorder: 5,
      title: 'opacity',
      group: 'style',
      value: 1,
      settings: {
        min: 0,
        max: 1,
        step: 0.05,
        decimals: 2,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 200,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 200,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
