import ModelElement from './v-element'
import { programElementTypes, variableTypes as varTypes, libraryGroups } from '/@shared/constants'
import _get from 'lodash.get'
import { isStringValidArray } from '/@shared/utils'
import { variableTypes, valueTypes } from '/@models/v-program/v-elements/v-element'

export default class ModelSet extends ModelElement {
  get variableType() {
    if (this.variable.startsWith('root/')) {
      return variableTypes.PARAMETER
    }

    return this.isNewVariable //
      ? variableTypes.CREATE_VARIABLE
      : variableTypes.SET_VARIABLE
  }

  get isValidGeometryVariable() {
    return isStringValidArray(this.value) && JSON.parse(this.value).length === 6
  }

  get isCreateVariable() {
    return this.variableType === variableTypes.CREATE_VARIABLE
  }

  get isSetVariable() {
    return this.variableType === variableTypes.SET_VARIABLE
  }

  get isParameter() {
    return this.variableType === variableTypes.PARAMETER
  }

  initVariables() {
    super.initVariables()

    this._type = programElementTypes.set
    this._title = 'set'
    this._icon = 'v-set'
    this._canHaveChildren = false
    this._variable = ''
    this._value = ''
    this._variableObjectID = null
    this._valueObjectID = null
    this._sortOrder = 0
    this._libraryGroup = libraryGroups.VARS
  }

  toJS() {
    if (!this.hasVariable || !this.hasVariable) {
      return ''
    }

    let js = `nop(new LineId("${this.id}"));
    `

    switch (this.variableType) {
      case variableTypes.CREATE_VARIABLE:
      case variableTypes.SET_VARIABLE:
        js += this.getCreateVariableJS()
        break
      case variableTypes.PARAMETER:
        js += this.getSetParameterJS()
        break
    }

    return js
  }

  getCreateVariableJS() {
    const prefix = this.isNewVariable ? 'var ' : ' '

    switch (this.valueType) {
      case valueTypes.VARIABLE:
        return `${prefix}${this.getVariableJS()} = ${this.getValueJS()};`
      case valueTypes.VALUE:
        return `${prefix}${this.getVariableJS()} = ${this.value};`
      case valueTypes.PARAMETER:
        return `${prefix}${this.variable} = get("${this.value}");`
    }
  }

  getSetParameterJS() {
    switch (this.valueType) {
      case valueTypes.VARIABLE:
        return `set("${this.variable}", ${this.getValueJS()});`
      case valueTypes.VALUE:
        return `set("${this.variable}", ${this.value});`
      case valueTypes.PARAMETER:
        return `set("${this.variable}", get("${this.value}"));`
    }
  }

  getVariableJS() {
    const variableInfoObject = this.methodModel.getVariableInfoObject(this.variable, this.id)
    let scopePrefix = ''

    if (variableInfoObject.type === varTypes.GEOMETRY) {
      scopePrefix = 'this.'
    }

    return scopePrefix + this.variable
  }

  getValueJS() {
    // todo fix this method can be different
    const variableInfoObject = this.methodModel.getVariableInfoObject(this.value)
    let scopePrefix = ''

    if (variableInfoObject.type === varTypes.GEOMETRY) {
      scopePrefix = 'this.'
    }

    return `clone(${scopePrefix}${this.value})`
  }
}
