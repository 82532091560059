import ModelBase from './base'
import {
  componentTypes,
  inputComponentTypes,
  colorScheme,
  subscriptionScopes,
  variableTypes,
} from '/@/shared/constants'
import { uuid, empty } from '/@/shared/utils'
import ModelPoint from './v-point'
import _cloneDeep from 'lodash.clonedeep'
import _get from 'lodash.get'
import vuexStore from '/@vuex/store'
import { types } from '/@vuex/types'
import collect from 'collect.js'
import ModelRootPoint from './v-root-point'

export default class ModelGeometry extends ModelBase {
  get variables() {
    return this.rootPoint
      .all()
      .filter((v) => !empty(v.title))
      .map((v) => {
        return {
          type: variableTypes.GEOMETRY,
          id: v.id,
          title: v.title,
          cartPoseAbsolute: v.cartPoseAbsolute,
        }
      })
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vGeometry
    this._title = 'geometry'

    this._points = {
      id: uuid(),
      title: 'world',
      type: 'world',
      children: [
        {
          id: uuid(),
          title: 'robot',
          type: 'robot',
          children: [],
        },
      ],
    }

    this._programID = {
      sortorder: 3,
      title: 'pair program',
      icon: 'vGeometry',
      value: '',
      settings: {
        labels: {
          default: 'Select a program component to pair.',
          pairing: 'Click on the desired program panel.',
          paired: 'Program component paired.',
        },
        validType: componentTypes.vProgram,
        modelAttribute: 'programID',
      },
      inputComponentType: inputComponentTypes.pair,
    }

    this._3dID = {
      sortorder: 3,
      title: 'pair 3D',
      icon: 'v3d',
      value: '',
      settings: {
        labels: {
          default: 'Select a 3D component to pair.',
          pairing: 'Click on the desired 3D panel.',
          paired: '3D component paired.',
        },
        validType: componentTypes.v3d,
        modelAttribute: '3dID',
      },
      inputComponentType: inputComponentTypes.pair,
    }

    this._cartPath = {
      sortorder: 3,
      title: 'cart path',
      value: 'root/ManipulatorControl/manipulatorToolPoseActual',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
    }

    this._jointPath = {
      sortorder: 4,
      title: 'joint path',
      value: 'root/ManipulatorControl/jointPositionsActual',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
    }

    this._modePath = {
      sortorder: 4,
      title: 'mode command path',
      value: 'root/Logic/modeCommand',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.NONE,
    }

    this._semiAutoPath = {
      sortorder: 4,
      title: 'semiauto path',
      value: 'root/ManipulatorControl/activateSemiAuto',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.NONE,
    }

    this._semiAutoTargetPath = {
      sortorder: 4,
      title: 'semiauto target path',
      value: 'root/ManipulatorControl/semiAutoMotionGenerator/target',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.NONE,
    }

    this._teachPointPath = {
      sortorder: 5,
      title: 'teach point path',
      value: '',
      group: 'value',
      inputComponentType: inputComponentTypes.path,
      subscriptionScope: subscriptionScopes.ARTBOARD,
    }

    this._backgroundOpacity = {
      sortorder: 7,
      title: 'background opacity',
      group: 'style',
      value: 1,
      settings: {
        min: 0,
        max: 1,
        step: 0.05,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._backgroundColor = {
      sortorder: 8,
      title: 'background color',
      group: 'style',
      value: colorScheme.gray3,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 340,
      settings: {
        min: 340,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._height = {
      sortorder: 1001,
      title: 'height',
      group: 'position',
      value: 500,
      inputComponentType: inputComponentTypes.number,
    }

    this._systemId = {
      sortorder: 1002,
      title: 'System ID',
      group: 'value',
      value: null,
      inputComponentType: inputComponentTypes.number,
    }

    this._motionSLVersion = {
      sortorder: 1003,
      title: 'Message format',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.select,
      options: [
        {
          label: 'Robot v1-v3',
          key: ''
        },
        {
          label: 'Robot v4+',
          key: 'v1'
        }],
    }

    this._rootPoint = {}
  }

  boot() {
    this.rootPoint = new ModelRootPoint({
      ...this.points,
      geometryID: this.id,
      programID: this.programID.value,
    })
  }

  all() {
    return this.rootPoint.all().filter((p) => p.title !== '')
  }

  find(callback) {
    return this.rootPoint.first(callback)
  }

  hasPoint(callback) {
    return this.find(callback) !== false
  }

  get activeModels() {
    return vuexStore.getters[types.ARTBOARD_ACTIVE_MODELS]
  }

  get programModel() {
    return this.activeModels[this.programID.value]
  }

  get isPairedToProgram() {
    return !empty(this.programModel)
  }

  toJS() {
    return `var geometry = ${this.rootPoint.toJS()}`
  }
}
