import mixinArtboards from '/@mixins/artboards'
import { colorScheme } from '/@shared/constants'
import { extractTouch } from '/@shared/utils'
import deploy from './deploy/index.vue'
import raster from './raster/index.vue'
import component from './component/index.vue'
import artboard from './artboard/index.vue'
import states from './states/index.vue'
import errors from './errors/index.vue'
import logs from './logs/index.vue'
import _throttle from 'lodash/throttle'

const MIN_WIDTH = 320

export default {
  mixins: [mixinArtboards],
  components: {
    'v-deploy': deploy,
    'v-raster': raster,
    'v-screen': screen,
    'v-component': component,
    'v-artboard': artboard,
    'v-states': states,
    'v-errors': errors,
    'v-logs': logs,
  },
  data: function () {
    return {
      colorScheme: colorScheme,
      mouseStartPosition: {
        x: 0,
        y: 0,
      },
      widthOnStartDrag: MIN_WIDTH,
      width: MIN_WIDTH,
    }
  },
  computed: {
    isPanelComponentsVisible() {
      return this.activeModels.length > 0
    },
    styleObject() {
      return {
        'flex-basis': `${this.width}px`,
      }
    },
  },
  methods: {
    init() {
      this.dragMoveFunction = _throttle(this.dragMoveListener)
    },
    dragDownListener(event) {
      event = extractTouch(event)

      this.mouseStartPosition.x = event.clientX
      this.mouseStartPosition.y = event.clientY
      this.widthOnStartDrag = this.width

      window.addEventListener('touchend', this.dragUpListener)
      window.addEventListener('touchmove', this.dragMoveFunction)

      window.addEventListener('mouseup', this.dragUpListener)
      window.addEventListener('mousemove', this.dragMoveFunction)
    },
    dragUpListener() {
      window.removeEventListener('touchend', this.dragUpListener)
      window.removeEventListener('touchmove', this.dragMoveFunction)

      window.removeEventListener('mouseup', this.dragUpListener)
      window.removeEventListener('mousemove', this.dragMoveFunction)
    },
    dragMoveListener(event) {
      event = extractTouch(event)
      this.width = this.limit(this.widthOnStartDrag + this.deltaX(event))
    },
    limit(value) {
      return Math.max(MIN_WIDTH, Math.min(value, 700))
    },
    deltaX(event) {
      return this.mouseStartPosition.x - event.clientX
    },
  },
  mounted() {
    this.init()
  },
}
