import { types } from '/@vuex/types'
import { vueDeepSet } from '/@/shared/utils'
import {
  defaultErrorAcknowledgePath,
  defaultErrorDefFolder,
  defaultErrorFile,
  defaultStateFile,
  defaultErrorPath,
  defaultStatePath,
  defaultLogPath,
} from '/@/shared/config'
import _get from 'lodash.get'

const initialState = () => {
  return {
    gridVersion: 5,
    progVersion: 3,
    deviceSupportsTouch: false,
    permissions: [],
    api: {
      version: 0,
    },
    timePath: '',
    error: {
      file: defaultErrorFile,
      codes: {},
      definition: {
        folder: defaultErrorDefFolder,
      },
      paths: {
        acknowledge: defaultErrorAcknowledgePath,
        subscription: defaultErrorPath,
      },
      frequencyDivider: 1000,
    },
    state: {
      file: defaultStateFile,
      codes: {},
      paths: {
        subscription: defaultStatePath,
      },
      frequencyDivider: 1000,
    },
    log: {
      paths: {
        subscription: defaultLogPath,
      },
    },
    application: {
      fitToScreen: false,
      scale: 1,
    }
  }
}

const moduleSettings = {
  state: {
    ...initialState(),
  },
  mutations: {
    [types.SETTINGS_LOAD](state, value) {
      state.error.file = value.error.file
      state.error.definition.folder = value.error.definition.folder
      state.error.paths.acknowledge = value.error.paths.acknowledge
      state.error.paths.subscription = value.error.paths.subscription
      state.error.frequencyDivider = value.error.frequencyDivider || 1000
      state.error.codes = value.error.codes
      state.state.file = value.state.file
      state.state.codes = value.state.codes
      state.state.paths.subscription = value.state.paths.subscription
      state.state.frequencyDivider = value.state.frequencyDivider || 1000
      state.log.paths.subscription = value.log.paths.subscription
      state.timePath = value.timePath
      state.application.fitToScreen = value.application?.fitToScreen
    },
    [types.SETTINGS_ATTRIBUTE_UPDATE](state, payload) {
      vueDeepSet(state, payload.key, payload.value)
    },
  },
  getters: {
    [types.SETTINGS_STATE]: (state) => {
      return state
    },
    [types.SETTINGS_ATTRIBUTE]: (state) => (key) => {
      return _get(state, key)
    },
  },
}

export default moduleSettings
