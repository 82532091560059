import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelLabel extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vLabel
    this._title = 'label'

    this._label = {
      sortorder: 2,
      title: 'label',
      value: 'label',
      group: 'style',
      inputComponentType: inputComponentTypes.input,
    }

    this._fontSize = {
      sortorder: 3,
      title: 'font size',
      group: 'style',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontWeight = {
      sortorder: 4,
      title: 'font weight',
      value: 'normal',
      group: 'style',
      inputComponentType: inputComponentTypes.input,
    }

    this._lineHeight = {
      sortorder: 5,
      title: 'line height',
      group: 'style',
      value: 15,
      settings: {
        min: 0,
      },
      inputComponentType: inputComponentTypes.number,
    }

    this._color = {
      sortorder: 6,
      title: 'color',
      group: 'style',
      value: colorScheme.gray6,
      inputComponentType: inputComponentTypes.colorPicker,
    }
  }
}
