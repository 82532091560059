import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme, subscriptionScopes } from '/@/shared/constants'
import _get from 'lodash.get'

export default class ModelMeter extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vMeter
    this._title = 'meter'

    this._path = {
      title: 'path',
      value: '',
      sortorder: 1,
      settings: {
        slot: null,
        expression: '',
        prefix: '',
      },
      inputComponentType: inputComponentTypes.path,
    }

    this._orientation = {
      title: 'orientation',
      group: 'style',
      sortorder: 3,
      options: [
        {
          title: 'horizontal',
          value: 'horizontal',
        },
        {
          title: 'vertical',
          value: 'vertical',
        },
      ],
      value: 'horizontal',
      inputComponentType: inputComponentTypes.radio,
    }

    this._showValue = {
      title: 'show value',
      group: 'style',
      value: true,
      sortorder: 4,
      inputComponentType: inputComponentTypes.checkbox,
    }

    this._unit = {
      title: 'unit',
      group: 'value',
      value: '',
      sortorder: 5,
      inputComponentType: inputComponentTypes.input,
    }

    this._decimals = {
      title: 'decimals',
      group: 'value',
      value: 2,
      sortorder: 6,
      inputComponentType: inputComponentTypes.input,
      settings: {
        min: 0,
        max: 100,
      },
    }

    this._width = {
      sortorder: 7,
      title: 'size',
      group: 'position',
      value: 400,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._break0 = {
      title: 'min',
      value: 0,
      sortorder: 8,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.redDark,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break1 = {
      title: 'break 1',
      value: 16,
      sortorder: 9,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.orange,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break2 = {
      title: 'break 2',
      value: 32,
      sortorder: 10,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.yellow,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break3 = {
      title: 'break 3',
      value: 48,
      sortorder: 11,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.lightGreen,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break4 = {
      title: 'break 4',
      value: 64,
      sortorder: 12,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.green,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break5 = {
      title: 'break 5',
      value: 79,
      sortorder: 13,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        color: colorScheme.blue,
        path: '',
        slot: null,
        expression: '',
      },
    }

    this._break6 = {
      title: 'max',
      value: 100,
      sortorder: 14,
      inputComponentType: inputComponentTypes.break,
      settings: {
        inputType: 'value',
        isLabelVisible: true,
        slot: null,
        expression: '',
      },
    }
  }
}
