import session from '/@shared/session'
import { handleRouteError } from '/@shared/utils'

export default {
  render: () => null,
  mounted() {
    // TODO:: after migrating to sanctum this component no longer authenticates. Rename in the future
    session.set('project', decodeURIComponent(this.$route.query.project))
    session.set('grid', decodeURIComponent(this.$route.query.grid))

    const name = IS_DEPLOYED ? 'front' : 'admin'

    this.$router.push({ name }).catch((error) => handleRouteError(error))
  },
}
