import ModelElement from './v-element'
import { programElementTypes, libraryGroups } from '/@/shared/constants'

export default class ModelWait extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.wait
    this._title = 'wait'
    this._icon = 'v-wait'
    this._canHaveChildren = false
    this._duration = -1
    this._variable = ''
    this._value = ''
    this._variableObjectID = null
    this._valueObjectID = null
    this._sortOrder = 1
    this._libraryGroup = libraryGroups.FLOW
  }

  toJS() {
    let value = this.value === '' ? '""' : this.value
    let duration = this.duration

    if (duration === '∞') {
      duration = -1
    } else {
      duration = Number.parseFloat(duration)

      if (isNaN(duration) || duration < -1) {
        duration = -1
      }
    }

    return `
      nop(new LineId("${this.id}"));

      wait(new Wait(${duration}, "${this.variable}", ${value}));`
  }
}
