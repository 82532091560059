import defaultPlugin from 'store/plugins/defaults'
import engine from 'store/src/store-engine'
import sessionStorage from 'store/storages/sessionStorage'

const storages = [sessionStorage]
const plugins = [defaultPlugin]

const sessionStore = engine.createStore(storages, plugins)

export default sessionStore
