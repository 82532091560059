import { types } from '/@vuex/types'
import _debounce from 'lodash/debounce'
import vuexStore from '/@vuex/store'
import { gridSaveDelay } from '/@/shared/config'
import collect from 'collect.js'
import { componentTypes } from '/@/shared/constants'
import http from '/@shared/http'
import md5 from 'md5'
import _get from 'lodash.get'
import session from '/@shared/session'
import { cleanPath } from '/@shared/utils'

const hookMutations = [types.MODEL_UPDATE, types.MODELS_UPDATE]
const savedPrograms = {}

const hasProgramChanged = (path, data) => {
  const checkSum = md5(data)

  if (savedPrograms.hasOwnProperty(path) && savedPrograms[path] === checkSum) {
    return false
  }

  savedPrograms[path] = checkSum
  return true
}

const save = () => {
  const models = getProgramModels()

  models.each((model) => {
    const isDefaultProgram = model.programTitle.includes('untitled')

    if (isDefaultProgram) {
      return
    }

    const project = session.get('project')
    const api_key = session.get('token')
    const path = getProgramPath(model)
    const data = JSON.stringify(model.toStorageJSON())

    const programUpdateCounter = vuexStore.getters[types.ARTBOARDS_ATTRIBUTE]('programUpdateCounter')

    if (!hasProgramChanged(path, data)) {
      return
    }

    http.post('fs/files/update', {
      project,
      path,
      data,
      api_key,
    }).then(() => {
      vuexStore.commit(types.ARTBOARDS_ATTRIBUTE_UPDATE, {
        key: 'programUpdateCounter',
        value: programUpdateCounter + 1,
      })
    })
  })
}

const getGridDirname = () => {
  const gridPath = session.get('grid')

  if (!gridPath.includes('/')) {
    return ''
  }

  return gridPath.split('/').slice(0, -1).join('/').concat('/')
}

const getProgramPath = (model) => {
  const path = model.storagePath.value
  let title = model.programTitle

  if (!title.endsWith('.prog')) {
    title += '.prog'
  }

  return cleanPath(getGridDirname() + path) + '/' + title
}

const getProgramModels = () => {
  return collect(getArtboardDictionary())
    .values()
    .pluck('components')
    .flatten(1)
    .filter((c) => c.type === componentTypes.vProgram)
}

const getArtboardDictionary = () => {
  return vuexStore.getters[types.ARTBOARDS_ATTRIBUTE]('dictionary')
}

const checkSaveMutation = _debounce((mutation) => {
  const isHookMutation = hookMutations.includes(mutation.type)
  const isFront = !window.location.pathname.includes('admin')
  const saveMutation = _get(mutation, 'payload.saveMutation', true)

  if (
    isFront &&
    isHookMutation &&
    saveMutation
  ) {
    save()
  }
}, gridSaveDelay)

const pluginProgramStorage = (vuexStore) => {
  vuexStore.subscribe(checkSaveMutation)
}

export default pluginProgramStorage
