import ModelElement from './v-element'
import { programElementTypes, variableTypes, libraryGroups } from '/@shared/constants'

export default class ModelCall extends ModelElement {
  initVariables() {
    super.initVariables()

    this._type = programElementTypes.call
    this._title = 'call'
    this._icon = 'v-call'
    this._canHaveChildren = false
    this._variable = 'this'
    this._value = ''
    this._methodArguments = []
    this._sortOrder = 1
    this._libraryGroup = libraryGroups.SUBPROG
  }

  toJS() {
    if (!this.hasVariable || !this.hasValue) {
      return ''
    }

    return `
      nop(new LineId("${this.id}"));
      ${this.variable}.${this.value}(${this.getArgumentString()})`
  }

  getArgumentString() {
    return this.methodArguments
      .filter((a) => a.title !== null)
      .map((a) => {
        return [variableTypes.GEOMETRY, variableTypes.ARGUMENT_CONSTRUCTOR].includes(a.type)
          ? `this.${a.title}`
          : a.title
      })
      .join(', ')
  }
}
