import { types } from '/@vuex/types'
import { mapGetters } from 'vuex'

export default {
  methods: {
    rasterUpdateAttribute(key, value) {
      this.$store.commit(types.RASTER_ATTRIBUTE_UPDATE, {
        key,
        value,
      })
    },
  },
  computed: {
    ...mapGetters({
      raster: types.RASTER_STATE,
      rasterAttribute: types.RASTER_ATTRIBUTE,
    }),
  },
}
