import ModelBase from './base'
import { inputComponentTypes, componentTypes, colorScheme } from '/@/shared/constants'

export default class ModelHtml extends ModelBase {
  constructor(data) {
    super(data)
  }

  initVariables() {
    super.initVariables()

    this._type = componentTypes.vHtml
    this._title = 'html'

    this._html = {
      sortorder: 2,
      title: 'html',
      icon: 'code',
      group: 'value',
      value: '',
      inputComponentType: inputComponentTypes.textarea,
    }

    this._fontSize = {
      sortorder: 3,
      title: 'font size',
      group: 'style',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._fontWeight = {
      sortorder: 4,
      title: 'font weight',
      value: 'normal',
      group: 'style',
      inputComponentType: inputComponentTypes.input,
    }

    this._lineHeight = {
      sortorder: 5,
      title: 'line height',
      group: 'style',
      value: 15,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }

    this._backgroundColor = {
      sortorder: 6,
      title: 'background',
      group: 'style',
      value: colorScheme.black,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._color = {
      sortorder: 6,
      title: 'color',
      group: 'style',
      value: colorScheme.gray6,
      inputComponentType: inputComponentTypes.colorPicker,
    }

    this._width = {
      sortorder: 1000,
      title: 'width',
      group: 'position',
      value: 200,
      inputComponentType: inputComponentTypes.number,
      settings: {
        min: 0,
      },
    }
  }
}
